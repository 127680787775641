import {
  AppCamsModelRouteKey,
  AppCommonRouteKey,
} from "core/stores/route/enums";
import { ShowcaseStep } from "library/components/_tailwind/showcase";
import { languageStore } from "library/core/stores/language/LanguageStore";

export const ShowcasePages: (AppCommonRouteKey | AppCamsModelRouteKey)[] = [
  AppCamsModelRouteKey.myPage,
  AppCamsModelRouteKey.myFans,
  AppCamsModelRouteKey.referrals,
  AppCommonRouteKey.earnings,
  AppCommonRouteKey.messages,
  AppCamsModelRouteKey.leaderboards,
  AppCommonRouteKey.broadcast,
  AppCommonRouteKey.payout,
];

export const SHOWCASE_TARGET_CLASSNAMES = {
  myPageNavItem: "NavigationItem__myPage",
  myPageProfilePhotoNude: "MyPageProfileBio__nude_photo_title",
  myPageVideoTitle: "NavigationItem__myVideos",
  referralsMemberLink: "Referrals__member_link_title",
  referralsModelLink: "Referrals__model_link_title",
  broadcastTokens: "BroadcastControl__tokens_to_enter",
  broadcastShowTypes: "BroadcastControl__show_types",
  broadcastWheelOfFun: "BroadcastControl__wheel_of_fun",
  quickReplyTitle: "ChatTab__quick-reply-title",
  earningsYouMake: "EarningsReport__you_make_header",
  earningsTier: "TierInfoTable__header_tokens",
  leaderboardModelOfDay: "LeaderBoard__model_of_day",
  messagesTitle: "Messages__title",
  payoutTitle: "Payout__title",
  broadcastPreviewBox: "BroadcastPreview",
  broadcastControls: "BroadcastControls",
  broadcastTabs: "BroadcastTabs",
  broadcastChatBox: "BroadcastChatBox",
};

export const BROADCAST_SHOWCASE_STEPS: ShowcaseStep[] = [
  {
    content: languageStore.intl.formatMessage({
      id: "broadcast.tour-1",
      defaultMessage: "Welcome to the Streamray broadcaster! This is a quick tour to help you get the most out of your streaming sessions.",
    }),      
    placement: "center",
    target: "body",
    disableBeacon: true,
  },
  {
    content: languageStore.intl.formatMessage({
      id: "broadcast.tour-2",
      defaultMessage: "Here you can select the method you will use to send us your stream: free OBS software or straight from your webcam device.",
    }),
    placement: "bottom-start",
    target: `.${SHOWCASE_TARGET_CLASSNAMES.broadcastPreviewBox}`,
    disableBeacon: true,
  },
  {
    content: languageStore.intl.formatMessage({
      id: "broadcast.tour-3",
      defaultMessage: "The three show modes are: Free, Nude, and Goal. Select one and set your options, including prices. You can change show modes any time while broadcasting.",
    }),
    placement: "top-end",
    target: `.${SHOWCASE_TARGET_CLASSNAMES.broadcastControls}`,
    disableBeacon: true,
  },
  {
    content: languageStore.intl.formatMessage({
      id: "broadcast.tour-4",
      defaultMessage: "Once you begin broadcasting, this is where the viewer list for your live stream will appear. You can perform various actions on members by clicking the icons next to their names. \"Members\" have created a free account with a username. \"Guests\" are only watching and cannot interact with you.",
    }),
    placement: "top-start",
    target: `.${SHOWCASE_TARGET_CLASSNAMES.broadcastTabs}`,
    disableBeacon: true,
  },
  {
    content: languageStore.intl.formatMessage({
      id: "broadcast.tour-5",
      defaultMessage: "This is where the chat messages appear. Also, any tips, gifts, buzzes, or other interactions will appear here.",
    }),
    placement: "right",
    target: `.${SHOWCASE_TARGET_CLASSNAMES.broadcastChatBox}`,
    disableBeacon: true,
    width: "w-80",
    pseudoClasses: ["xs:w-auto", "sm:w-auto", "md:w-auto", "lg:w-60"],
    minWidth: "min-w-0",
  },
  {
    content: languageStore.intl.formatMessage({
      id: "broadcast.tour-6",
      defaultMessage: "Happy Streaming! If you have any questions about this broadcaster, our site, or your account, please contact us at any time through the support link in the Model Center. We are happy to help! You can launch this tour again by clicking the Tour link.",
    }),
    placement: "center",
    target: `body`,
    disableBeacon: true,
  },
];
