import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ChatBox from "./broadcast-chat";
import BroadcastPreview from "./broadcast-preview";
import _ from "lodash";
import { Prompt } from "react-router";
import { Action, Location } from "history";

import "./styles.scss";
import ProfileStore from "../my-page/stores/profile/ProfileStore";
import { inject, observer } from "mobx-react";
import BroadcastStore from "./_stores/broadcast/BroadcastStore";
import TailwindFlex from "library/components/_tailwind/flex";
import AuthStore from "core/stores/auth/AuthStore";
import { AppCommonRouteKey } from "core/stores/route/enums";
import TailwindSpinner from "library/components/_tailwind/spinner";
import TailwindButton from "library/components/_tailwind/button";
import TailwindGrid from "library/components/_tailwind/grid";
import BroadcastTabs from "./broadcast-tabs";
import LayoutStore from "library/core/stores/layout/LayoutStore";
import BroadcastWheelOfFunPopover from "./broadcast-popovers/broadcast-wheel-of-fun-popover";
import Showcase from "library/components/_tailwind/showcase";
import ShowcaseStore from "core/stores/showcase/ShowcaseStore";
import AccountAuditedDisclaimer from "common/account-audited-disclaimer";
import BroadcastBuzzPrivateRecordStopBroadcastOptionsBar from "common/broadcast/broadcast-buzz-private-record-options-bar";
import BroadcastStrategy from "common/broadcast/_stores/BroadcastStrategy";
import { BROADCAST_SHOWCASE_STEPS } from "core/stores/showcase/const";
import { MainTab } from "./_stores/broadcast/enums";
import BroadcastIsAlreadyBroadcastingError from "common/broadcast/broadcast-is-already-broadcasting-error";
import BroadcastStreamStore from "./_stores/broadcast-stream/BroadcastStreamStore";
import Modal from "library/components/modal";
import { history } from "library/core/utility";
import TailwindHeading from "library/components/_tailwind/heading";
import BroadcastIsPendingOrDeniedError from "common/broadcast/broadcast-is-pending-or-denied-error";
import BroadcastAloneOrWithGuestsSelector from "common/broadcast/broadcast-alone-or-with-guests-selector";
import BroadcastVideoSettingsPopover from "./broadcast-popovers/broadcast-settings-popover";
import { BroadcastingWithGuestsIndicator } from "./broadcast-with-guests-indicator";
import MissingPgProfilePhotoError from "./missing-pg-profile-photo-error";
import LanguageStore from "library/core/stores/language/LanguageStore";
import config from "core/config";

type BroadcastContainerProps = {
  profileStore?: ProfileStore;
  broadcastStore?: BroadcastStore;
  authStore?: AuthStore;
  layoutStore?: LayoutStore;
  showcaseStore?: ShowcaseStore;
  broadcastStrategy?: BroadcastStrategy;
  broadcastStreamStore?: BroadcastStreamStore;
  languageStore?: LanguageStore;
};

const BroadcastContainer: React.ComponentType<
  BroadcastContainerProps
> = ({
  profileStore,
  authStore,
  broadcastStore,
  layoutStore,
  showcaseStore,
  broadcastStrategy,
  broadcastStreamStore,
  languageStore,
}) => {
  const {
    getWelcomeShowcaseSteps,
    nextWelcomeShowcasePage,
    playingShowcase,
    playingBroadcastShowcase,
    disableShowcase,
  } = showcaseStore!;
  const {
    isVideoSettingsPopoverShown,
    isWheelOfFunSettingsPopoverShown,
    setCurrentMainTab,
    isAlreadyBroadcastingInAnotherWindow,
    terminateActiveBroadcastingSession,
    streamState,
    isBroadcastingModeSelected,
    setIsBroadcastingModeSelected,
    stopStreaming,
  } = broadcastStore!;
  const { streamType, isStreamingOBS } = broadcastStreamStore!;
  const {
    isStudioModel,
    profileRegistrationCompleted,
    modelProfile,
    loadingProfile,
    setShouldHideBroadcastTutorialToModel,
    shouldHideBroadcastTutorialToModel,
  } = profileStore!;

  const { intl } = languageStore!;
  const { connect, disconnect } = broadcastStrategy!;
  const { openRegisterModalToCompleteProfile } = authStore!;
  const { setBodyPadding, isMobileDevice, screenOrientation } = layoutStore!;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [leaveModalVisible, setLeaveModalVisible] = useState<boolean>(false);
  const [confirmedLeave, setConfirmedLeave] = useState<boolean>(false);
  const [redirectTo, setRedirectTo] = useState<string>("");

  useEffect(() => {
    setBodyPadding(0);
    setRedirectTo("");

    return () => {
      setBodyPadding(15);
      setIsBroadcastingModeSelected(false);
    };
  }, []);

  useEffect(() => {
    if (!profileRegistrationCompleted && modelProfile.id) {
      openRegisterModalToCompleteProfile(undefined, true);
    } else if (modelProfile.id) {
      connect();
    }
  }, [profileRegistrationCompleted, modelProfile]);

  const setBroadcastTutorialViewedOnce = _.debounce(
    setShouldHideBroadcastTutorialToModel,
    100
  );

  const whenLeavePrompt = useMemo(() => {
    return streamType !== null;
  }, [streamState, streamType]);

  const handleBlockedNavigation = useCallback(
    (nextLocation: Location, action: Action) => {
      if (!confirmedLeave && whenLeavePrompt && action === "PUSH") {
        setLeaveModalVisible(true);
        setRedirectTo(nextLocation.pathname);
        return false;
      }

      return true;
    },
    [confirmedLeave, whenLeavePrompt]
  );

  const handleConfirmNavigationClick = () => {
    disconnect();
    setLeaveModalVisible(false);
    setConfirmedLeave(true);
  };

  const onCloseLeaveModalClicked = useCallback(() => {
    setLeaveModalVisible(false);
  }, []);

  const beforeUnloadListener = () => {
    stopStreaming();
    disconnect();
  };

  useEffect(() => {
    scrollContainerRef.current?.scrollTo({ top: 0 });
  }, [
    isVideoSettingsPopoverShown,
    isWheelOfFunSettingsPopoverShown,
    scrollContainerRef,
  ]);

  useEffect(() => {
    if (confirmedLeave && redirectTo) {
      // Navigate to the previous blocked location with your navigate function
      history.push(redirectTo);
    }
  }, [confirmedLeave, redirectTo]);

  //this will be fired when model is streaming on obs and closes tab/browser and streaming on obs
  useEffect(() => {
    if (isStreamingOBS) {
      addEventListener("beforeunload", e => {
        e.stopImmediatePropagation();
        e.preventDefault();
        beforeUnloadListener();
      });
    }
  }, [isStreamingOBS]);

  const isMobileAndLandscape = useMemo(
    () => isMobileDevice && screenOrientation === "landscape",
    [isMobileDevice, screenOrientation]
  );

  if (loadingProfile) {
    return <TailwindSpinner />;
  }

  if (modelProfile.status == "DENIED") {
    return <BroadcastIsPendingOrDeniedError status={"denied"} />;
  } else if (modelProfile.status === "PENDING") {
    return <BroadcastIsPendingOrDeniedError status={"pending"} />;
  } else if (modelProfile.is_broadcast_allowed === false) {
    return (
      <AccountAuditedDisclaimer
        containerProps={{ width: "w-full", md: "w-full", padding: ["pt-4"] }}
      />
    );
  } else if (modelProfile.non_nude_profile_image === null) {
    return <MissingPgProfilePhotoError />;
  } else if (isAlreadyBroadcastingInAnotherWindow) {
    return (
      <BroadcastIsAlreadyBroadcastingError
        onTerminateSession={terminateActiveBroadcastingSession}
      />
    );
  } else if (
    config.enableGuestModels &&
    !isBroadcastingModeSelected &&
    modelProfile.access_rights?.is_allow_to_invite_allowed
  ) {
    return <BroadcastAloneOrWithGuestsSelector />;
  }

  return (
    <>
      <Prompt when={whenLeavePrompt} message={handleBlockedNavigation} />
      <Modal open={leaveModalVisible} closeModal={onCloseLeaveModalClicked}>
        <TailwindFlex
          width={"w-192"}
          md={"w-11/12"}
          sm={"w-full"}
          height={"h-full"}
          alignItems={"items-center"}
          justifyContent={"justify-center"}>
          <TailwindFlex
            justifyContent={"justify-center"}
            padding={["p-10"]}
            sm={"w-full"}
            flexDirection={"flex-col"}
            backgroundColor={"bg-card-secondary-bg-color"}
            borderRadius={"rounded-3xl"}>
            <TailwindFlex justifyContent={"justify-center"} margin={["mb-4"]}>
              <TailwindHeading
                level={5}
                textAlign={"text-center"}
                textColor={"text-main-color"}>
                  {intl.formatMessage({
                    id: "notificationMessage.areYouSure",
                    defaultMessage:
                      "Your broadcast will close if you leave this page. Are you sure you want to leave?",
                  })}
              </TailwindHeading>
            </TailwindFlex>
            <TailwindFlex justifyContent={"justify-around"}>
              <TailwindFlex
                width={"w-3/12"}
                md={"w-1/3"}
                justifyContent={"justify-center"}>
                <TailwindButton
                  onClick={onCloseLeaveModalClicked}
                  fullWidth
                  rounded={false}
                  backgroundColor={"bg-transparent"}
                  textProps={{
                    textColor: "text-main-color",
                    textTransform: "uppercase",
                  }}
                  display={"flex"}
                  borderColor={"border-modal-button-color"}
                  borderWidth={["border-2"]}
                  alignItems={"items-center"}
                  justifyContent={"justify-center"}>
                  {intl.formatMessage({
                    id: "verbiage.cancel",
                    defaultMessage: "Cancel",
                  })}
                </TailwindButton>
              </TailwindFlex>
              <TailwindFlex
                width={"w-3/12"}
                md={"w-1/3"}
                justifyContent={"justify-center"}>
                <TailwindButton
                  onClick={handleConfirmNavigationClick}
                  rounded={false}
                  backgroundColor={"bg-modal-button-color"}
                  display={"flex"}
                  alignItems={"items-center"}
                  justifyContent={"justify-center"}
                  textProps={{
                    textColor: "text-white",
                    textTransform: "uppercase",
                  }}>
                  {intl.formatMessage({
                    id: "common.yes",
                    defaultMessage: "Yes",
                  })}
                </TailwindButton>
              </TailwindFlex>
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>
      </Modal>
      <TailwindGrid
        height={isMobileAndLandscape ? "h-auto" : "h-full"}
        cols={"grid-cols-3"}
        rows={"grid-rows-1"}
        overflow={"overflow-hidden"}
        pseudoClasses={[
          "md:grid-rows-none",
          "md:gap-2",
          "md:h-auto",
          "md:overflow-auto",
        ]}
        md={{
          cols: "grid-cols-1",
          rows: "grid-rows-2",
        }}
        lg={{
          cols: "grid-cols-2",
          rows: "grid-rows-1",
        }}
        xl={{
          cols: "grid-cols-5",
          rows: "grid-rows-1",
        }}
        xxl={{
          cols: "grid-cols-4",
          rows: "grid-rows-1",
        }}
        gap={"gap-4"}>
        <TailwindFlex
          className={["col-span-2"]}
          order={"order-1"}
          padding={["p-4", "pr-0"]}
          pseudoClasses={[
            "md:order-2",
            "md:h-auto",
            "md:col-span-1",
            "md:overflow-hidden",
            "md:pr-4",
            "lg:col-span-1",
            "xl:col-span-3",
            "2xl:col-span-3",
          ]}
          minHeight={"min-h-vh-1/2"}
          height={"h-full"}>
          <ChatBox />
        </TailwindFlex>
        <TailwindFlex
          order={"order-2"}
          padding={["p-4", "pl-0"]}
          flexDirection={"flex-col"}
          pseudoClasses={[
            "md:order-1",
            "md:overflow-visible",
            "md:pl-4",
            "lg:col-span-1",
            "xl:col-span-2",
            "2xl:col-span-1",
          ]}
          maxHeight={"max-h-full"}
          height={"h-full"}
          ref={scrollContainerRef}
          overflow={"overflow-hidden"}
          position={"relative"}>
          <BroadcastingWithGuestsIndicator />
          <BroadcastPreview />
          <TailwindFlex
            className={["BroadcastControls"]}
            height={"h-full"}
            minHeight='min-h-0'
            flexDirection={"flex-col"}>
            <BroadcastBuzzPrivateRecordStopBroadcastOptionsBar />
            <BroadcastTabs />
          </TailwindFlex>
          {isVideoSettingsPopoverShown && <BroadcastVideoSettingsPopover />}
          {/* {isAudioSettingsPopverShown && !isStreamingOBS && <BroadcastAudioSettingsPopover />} */}
          {isWheelOfFunSettingsPopoverShown && <BroadcastWheelOfFunPopover />}
        </TailwindFlex>
      </TailwindGrid>
      {playingShowcase && (
        <Showcase
          disableScrolling={true}
          steps={getWelcomeShowcaseSteps(AppCommonRouteKey.broadcast)}
          onFinish={nextWelcomeShowcasePage}
          enableDoneButton={isStudioModel ? true : false}
          disableOverlay={true}
          showSkipButton={true}
          onSkipped={() => {
            disableShowcase();
          }}
        />
      )}
      {(!shouldHideBroadcastTutorialToModel || playingBroadcastShowcase) &&
        !playingShowcase && (
          <Showcase
            disableScrolling={true}
            steps={BROADCAST_SHOWCASE_STEPS}
            onBeforeStep={index => {
              if (index === 2) {
                setCurrentMainTab(MainTab.SHOW_TYPES);
              } else if (index === 3) {
                setCurrentMainTab(MainTab.VIEWERS);
              }
            }}
            onFinish={() => {
              if (!shouldHideBroadcastTutorialToModel) {
                setBroadcastTutorialViewedOnce();
              }
              setCurrentMainTab(MainTab.SHOW_TYPES);
              disableShowcase("broadcast");
            }}
            onSkipped={() => {
              if (!shouldHideBroadcastTutorialToModel) {
                setBroadcastTutorialViewedOnce();
              }
              setCurrentMainTab(MainTab.SHOW_TYPES);
              disableShowcase("broadcast");
            }}
            showCloseButton={true}
            hideBackButton={false}
            hideLastStepNextButton={true}
            showStep={true}
            enableDoneButton={true}
          />
        )}
    </>
  );
};

  export default inject(
    "profileStore",
    "broadcastStore",
    "authStore",
    "layoutStore",
    "chatStore",
    "languageStore",
    "showcaseStore",
    "broadcastStrategy",
    "broadcastStreamStore"
  )(observer(BroadcastContainer));
