import axios from "axios";
import config from "./index";

const baseURL = config.apiUrl;

export default axios.create({
  baseURL: baseURL,
});
export const rawAxios = axios.create({
  baseURL: baseURL,
});
export const axiosBroadcast = axios.create({
  baseURL: config.broadcastApiUrl,
});
export const axiosUploader = axios.create({
  baseURL: config.uploaderApiUrl,
});
export const axiosMock = axios.create({
  baseURL: config.mockApi,
});
export const walletAxios = axios.create({
  baseURL: config.walletApiUrl,
});
export const axiosLocalhost = axios.create({
  baseURL: config.localhostUrl,
});
export const axiosProd = axios.create({
  baseURL: config.membersUrl,
});

export const cancelToken = axios.CancelToken;
