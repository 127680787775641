export type ChatSocketMessage = any & { action: string };

export enum MemberSortTypes {
  TOP_SPENDER = "top_spender",
  TIME_JOINED = "time_joined",
  REG_DATE = "reg_date",
  ALPHA = "alpha",
}

export type LovenseToyStatus = {
  id?: string;
  name?: string;
  type?: string;
  status?: string;
  version?: string;
  battery?: number;
};

