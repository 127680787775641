import React from "react";
import { inject, observer } from "mobx-react";
import BroadcastStore from "./_stores/broadcast/BroadcastStore";
import BroadcastControl from "./broadcast-control";
import TailwindCard from "library/components/_tailwind/card";
import PrivateRequestCard from "./broadcast-preview/broadcast-private-request";
import TailwindFlex from "library/components/_tailwind/flex";

type BroadcastSettingsTabProps = {
  broadcastStore?: BroadcastStore;
};

const BroadcastSettingsTab: React.ComponentType<BroadcastSettingsTabProps> = ({
  broadcastStore,
}) => {
  const { isPrivateConsentOpen } = broadcastStore!;

  return (
    <TailwindFlex
      flex={"flex-1"}
      className={["BroadcastSettingsTab", isPrivateConsentOpen ? 'overflow-y-auto' : '']}
      flexDirection={"flex-col"}>
      {isPrivateConsentOpen && <PrivateRequestCard />}
      <TailwindCard
        height={"h-full"}
        width={"w-full"}
        backgroundColor={"bg-card-secondary-bg-color"}>
        <BroadcastControl />
      </TailwindCard>
    </TailwindFlex>
  );
};

export default inject("broadcastStore")(observer(BroadcastSettingsTab));
