import { makeAutoObservable } from "mobx";
import axios from "axios";
import { api } from "core/utility";
import config from "core/config";
import _ from "lodash";
import { createFormData } from "core/utility/misc";
import {
  EMPTY_MODEL_GROUPED_STAR_RATINGS,
  InitialData,
  MODEL_PROFILE_DEFAULTS,
  MODEL_PROFILE_FIELDS_TO_BE_PATCHED,
  STUDIO_PROFILE_DEFAULTS,
  STUDIO_PROFILE_VALIDATION_FIELDS,
} from "./consts";
import {
  ModelAttribute,
  ModelKink,
  ModelProfile,
  ModelSpeciality,
  NonManagerProfile,
  Profile,
  StudioProfile,
  TaxFormStatus,
} from "./types";
import { IModelErrors, ProfileObjective } from "./interfaces";
import { ProfileDocumentType, UserAccountStatus } from "./enums";
import { ValidateOptions } from "library/core/stores/validation/types";

import { validationStore } from "library/core/stores/validation/ValidationStore";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import { ModelImage } from "containers/_components/media-manager/store/types";
import { formatter, history, logger } from "library/core/utility";
import { HashMap } from "library/core/interfaces";
import { Alpha3Language, UserRole } from "library/core/types";
import { openUploadTaxFormModal } from "core/stores/auth/utils";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import { routeStore } from "core/stores/route/RouteStore";
import {
  AppCamsModelRouteKey,
  AppVariantRouteKey,
} from "core/stores/route/enums";
import queryString from "query-string";
import { layoutStore } from "library/core/stores/layout/LayoutStore";
import {
  authStore,
  messageStore,
  profileStore,
  studioModelsAccessRightsStore,
  mediaManagerStore,
} from "core/stores";
import { getUserRoleFromDomain } from "library/core/stores/theme/utils";

const logPrefix = "[ProfileStore]:";

export default class ProfileStore {
  constructor() {
    makeAutoObservable(this);
  }
  public modelProfile: ModelProfile = InitialData.modelProfile;
  public studioProfile: StudioProfile = InitialData.studioProfile;
  public profileErrors: IModelErrors = InitialData.profileErrors;
  public loadingProfile: boolean = InitialData.loadingProfile;
  public profileRegistrationCompleted: boolean =
    InitialData.profileRegistrationCompleted;
  public profileInfoCompleted: boolean = InitialData.profileInfoCompleted;
  public profileLoaded: boolean =
    config.flightModeOn || InitialData.profileLoaded;
  public profileAccountStatus: UserAccountStatus | null =
    InitialData.profileAccountStatus;
  public profileCompletion: ProfileObjective[] = InitialData.profileCompletion;
  public alreadyOfferedToCompleteProfile: boolean =
    InitialData.alreadyOfferedToCompleteProfile;
  public loadingProfileCompletion: boolean =
    InitialData.loadingProfileCompletion;
  public isProfileImagesInProgress: boolean =
    InitialData.isProfileImagesInProgress;
  public isProfileBioFieldsComplete: boolean =
    InitialData.isProfileBioFieldsComplete;
  public profileImage: ModelImage | null = InitialData.profileImage;
  public nonNudeProfileImage: ModelImage | null =
    InitialData.nonNudeProfileImage;
  public publicProfileImage: ModelImage | null = InitialData.publicProfileImage;
  public publicNonNudeProfileImage: ModelImage | null =
    InitialData.publicNonNudeProfileImage;
  public activeAdmirerId: string | null = InitialData.activeAdmirerId;
  public languages: Alpha3Language[] = InitialData.languages;
  public languagesHashmap: HashMap<string> = InitialData.languagesHashmap;
  public kinks: ModelKink[] | null = InitialData.kinks;
  public kinksHashmap: HashMap<string> | null = InitialData.kinksHashmap;
  public specialities: ModelSpeciality[] | null = InitialData.specialities;
  public specialitiesHashmap: HashMap<string> | null =
    InitialData.specialitiesHashmap;
  public attributes: ModelAttribute[] | null = InitialData.attributes;
  public attributesHashmap: HashMap<string> | null =
    InitialData.attributesHashmap;
  public nonNudeProfileImageProgress: number | null =
    InitialData.nonNudeProfileImageProgress;
  public profileImageProgress: number | null = InitialData.profileImageProgress;
  public shouldShowTutorialToModel: boolean =
    InitialData.shouldShowTutorialToModel;
  public shouldHideBroadcastTutorialToModel: boolean =
    InitialData.shouldHideBroadcastTutorialToModel;
  mock2257WarningReason: string | undefined = undefined;
  mock2257WarningResult: string | undefined = undefined;
  mock2257WarningShouldShowMockImage: boolean = false;
  mock2257WarningHasAcknowledgedAllAudits: boolean | null = null;
  isFunFactSubmitted: boolean = false;
  profilePictureType: string = "PG";

  resetStore = () => {
    Object.entries(InitialData).map(([key, value]) => (this[key] = value));
  };

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  setMock2257WarningShouldShowMockImage = (shouldShow: boolean) => {
    this.mock2257WarningShouldShowMockImage = shouldShow;
  };

  setMock2257WarningHasAcknowledgedAllAudits = (has: boolean) => {
    this.mock2257WarningHasAcknowledgedAllAudits = has;
  };

  dismissTutorial = () => {
    this.setShouldShowTutorialToModel(false);
    const nextPage = this.getDefaultModelLoginUrl(false);
    history.push(nextPage);
  };

  setMock2257WarningReason = (mockReason: string | undefined) => {
    this.mock2257WarningReason = mockReason;
  };

  setMock2257WarningResult = (mockResult: string | undefined) => {
    this.mock2257WarningResult = mockResult;
  };

  get isSignAgreementIncomplete() {
    return this.profile?.missing_signup_fields?.includes("signed_agreement");
  }

  get isVerificationIncomplete() {
    return !!this.profile?.missing_signup_fields?.find(msf =>
      msf.startsWith("document")
    );
  }

  get isApplicationPending() {
    return (
      this.profile.status === "PENDING" &&
      !this.isVerificationIncomplete &&
      !this.isSignAgreementIncomplete
    );
  }

  get isApplicationIncomplete() {
    return (
      this.profile.status === "INCOMPLETE" ||
      this.isVerificationIncomplete ||
      this.isSignAgreementIncomplete
    );
  }

  get isApplicationDenied() {
    return this.profile.status === "DENIED";
  }

  get isSettingNudeProfilePicture() {
    return this.profilePictureType === "NUDE";
  }

  public setProfilePictureByType = (profilePictureType: string) => {
    this.profilePictureType = profilePictureType;
  };

  public getLanguages = async () => {
    try {
      const languagesResponse = await api.modelsBase.get(
        undefined,
        "languages"
      );

      this.languages = languagesResponse?.data?.results;
      this.languagesHashmap = this.languages.reduce(
        (accumulator, language) => ({
          ...accumulator,
          [language.alpha_3]: formatter.capitalizeText(language.name),
        }),
        {}
      );
    } catch (error) {
      this.log("get languages error ", error);
    }
  };

  public initProfile = async () => {
    this.loadingProfile = true;
    try {
      const userRole = getUserRoleFromDomain();
      const profileResponse = await api.getMeEndpoint(userRole as any).get();
      if (userRole === "model") {
        await this.initSpecialities();
        await this.initKinks();
        await this.initAttributes();
        const { reset_welcome } = queryString.parse(location.search);
        const shouldShowTutorial = await this.getShouldShowTutorialToModel();
        const defaultLoginUrl = this.getDefaultModelLoginUrl(
          (reset_welcome as unknown as boolean) || shouldShowTutorial
        );

        if (!layoutStore.isLiteMode) {
          if (
            (reset_welcome || shouldShowTutorial) &&
            history.location.pathname === "/"
          ) {
            history.replace(defaultLoginUrl);
          }
          await this.setShouldShowTutorialToModel(shouldShowTutorial);
        }
      }

      if (!!profileResponse) {
        const preparedProfile = this.prepareProfile(
          profileResponse.data,
          userRole
        );
        this.setProfile(
          preparedProfile,
          userRole,
          this.onBeforeProfileLoaded,
          this.onAfterProfileLoaded
        );
      }
    } catch (error) {
      this.log("getProfile Error ", config.showAjaxErrors ? error : "");
    } finally {
      this.loadingProfile = false;
    }
  };

  public initSpecialities = async () => {
    try {
      const { data } = await api.specialities.get();
      const specialities = data?.results.map(attribute => ({
        ...attribute,
        name: formatter.capitalizeText(attribute.name),
      }));
      this.specialities = specialities;
      this.specialitiesHashmap = this.convertArrayToObjectByKeyValue(
        specialities,
        "id",
        "name"
      );
    } catch (error) {
      this.log("Specialities Error");
    }
  };

  public initKinks = async () => {
    try {
      const { data } = await api.kinks.get();
      const kinks = data?.results.map(attribute => ({
        ...attribute,
        name: formatter.capitalizeText(attribute.name),
      }));
      this.kinks = kinks;
      this.kinksHashmap = this.convertArrayToObjectByKeyValue(
        kinks,
        "id",
        "name"
      );
    } catch (error) {
      this.log("Kinks Error");
    }
  };

  public initAttributes = async () => {
    try {
      const { data } = await api.attributes.get();

      const attributes = data?.results.map(attribute => ({
        ...attribute,
        name: formatter.capitalizeText(attribute.name),
      }));
      this.attributes = attributes;
      this.attributesHashmap = this.convertArrayToObjectByKeyValue(
        attributes,
        "id",
        "name"
      );
    } catch (error) {
      this.log("Attributes Error");
    }
  };

  public getShouldShowTutorialToModel = async () => {
    try {
      const {
        data: { hide_welcome_page, hide_broadcast_tutorial },
      } = await api.modelsWelcomeTutorial.get();
      this.shouldHideBroadcastTutorialToModel = hide_broadcast_tutorial;
      return !hide_welcome_page !== undefined
        ? (!hide_welcome_page as boolean)
        : false;
    } catch (error) {
      this.log("hide welcome page error");
      return false;
    }
  };

  public setShouldShowTutorialToModel = async (shouldShow: boolean) => {
    this.log("setShouldShowTutorialToModel started");
    try {
      this.shouldShowTutorialToModel = shouldShow;
      await api.modelsWelcomeTutorial.post({
        hide_welcome_page: !shouldShow,
      });
    } catch (error) {
      this.log("setShouldShowTutorialToModel failed", error);
    } finally {
      this.log("setShouldShowTutorialToModel finished");
    }
  };

  public setShouldHideBroadcastTutorialToModel = async () => {
    this.log("setShouldHideBroadcastTutorialToModel started");
    try {
      this.shouldHideBroadcastTutorialToModel = true;
      await api.modelsWelcomeTutorial.post({
        hide_broadcast_tutorial: true,
      });
    } catch (error) {
      this.log("setShouldHideBroadcastTutorialToModel failed", error);
    } finally {
      this.log("setShouldHideBroadcastTutorialToModel finished");
    }
  };

  getDefaultModelLoginUrl = (shouldShowTutorial: boolean) => {
    if (!shouldShowTutorial) {
      return routeStore.getSiteRouteURL(AppCamsModelRouteKey.myPage);
    } else {
      return routeStore.getSiteRouteURL(AppVariantRouteKey.tutorial);
    }
  };

  private convertArrayToObjectByKeyValue = (
    array: any[],
    key: string,
    value: string
  ) => {
    return _.chain(array).keyBy(key).mapValues(value).value();
  };

  private setProfile = (
    profileData: Profile,
    userRole: UserRole | null,
    onBeforeProfileLoaded?: Function,
    onAfterProfileLoaded?: Function
  ) => {
    if (userRole === "model") {
      this.modelProfile = profileData as ModelProfile;
    } else if (userRole === "studio") {
      this.studioProfile = profileData as StudioProfile;
    }
    this.setProfileImages(profileData);

    if (onBeforeProfileLoaded) {
      onBeforeProfileLoaded();
    }

    this.profileLoaded = true;

    if (onAfterProfileLoaded) {
      onAfterProfileLoaded();
    }
  };

  onBeforeProfileLoaded = () => {
    this.isProfileBioFieldsComplete = !!this.validateProfileBioFieldsComplete;
    this.profileAccountStatus = UserAccountStatus[this.profile.status];
    this.profileRegistrationCompleted =
      this.profile?.missing_signup_fields?.length === 0;
    this.profileInfoCompleted = true;

    if (
      !this.profileRegistrationCompleted &&
      !this.alreadyOfferedToCompleteProfile &&
      !this.isPendingAccountReview
    ) {
      authStore.openRegisterModalToCompleteProfile();
      if (this.isStudio) {
        this.alreadyOfferedToCompleteProfile = true;
      }
    } else if (profileStore.isTaxFormDenied) {
      openUploadTaxFormModal();
    }

    const { initRights } = studioModelsAccessRightsStore;
    initRights();
  };

  onAfterProfileLoaded = async () => {
    const { getSiteRouteURL } = routeStore;
    if (this.shouldShowTutorialToModel && this.isModel) {
      history.replace(getSiteRouteURL(AppVariantRouteKey.tutorial));
    }

    messageStore.initNotifications();
  };

  setProfileImages = (profileData: Profile) => {
    if (!this.isStudio) {
      const profile = profileData as NonManagerProfile;
      this.nonNudeProfileImage = profile.pending_non_nude_profile_image
        ? profile.pending_non_nude_profile_image
        : profile.non_nude_profile_image;
      this.publicNonNudeProfileImage =
        profile.public_profile?.non_nude_profile_image || null;
      this.profileImage = profile.pending_profile_image
        ? profile.pending_profile_image
        : profile.profile_image;
      this.publicProfileImage = profile.public_profile?.profile_image || null;
    }
  };

  setAccessRights = data => {
    this.setProfile(
      {
        ...this.modelProfile,
        ...{
          access_rights: data,
        },
      } as Profile,
      authStore.userRole
    );
  };

  get defaultProfile(): Profile {
    const userRole = getUserRoleFromDomain();
    if (userRole) {
      switch (userRole) {
        case "model":
          return MODEL_PROFILE_DEFAULTS;
        case "studio":
          return STUDIO_PROFILE_DEFAULTS;
        default:
          return MODEL_PROFILE_DEFAULTS;
      }
    }

    return MODEL_PROFILE_DEFAULTS;
  }

  get hasProfileLoaded() {
    if (config.flightModeOn || this.profile !== this.defaultProfile) {
      return true;
    }

    return false;
  }

  get profile(): Profile {
    const userRole = getUserRoleFromDomain();
    if (userRole) {
      switch (userRole) {
        case "model":
          return this.modelProfile;
        case "studio":
          return this.studioProfile;
      }
    }

    return this.modelProfile;
  }

  get profilePhotoURL(): string {
    if (!this.isStudio) {
      return (
        (this.profile as ModelProfile)?.public_profile?.non_nude_profile_image
          ?.image || ""
      );
    } else {
      return this.profile?.profile_image?.image;
    }
  }

  get isStudioModel() {
    return (
      authStore.userRole === "model" &&
      this.modelProfile.is_model_studio === false
    );
  }

  get isIndividualModel() {
    return (
      authStore.userRole === "model" &&
      this.modelProfile.is_model_studio === true
    );
  }

  get isModel() {
    return authStore.userRole === "model";
  }

  get isStudio() {
    return authStore.userRole === "studio";
  }

  get isPendingAccountReview() {
    return this.isModel && !this.modelProfile.is_broadcast_allowed;
  }

  public isCurrentAccountStatus = (
    status:
      | "INCOMPLETE"
      | "APPROVED"
      | "PENDING"
      | "DENIED"
      | "SUSPENDED"
      | "SUSPENDED_TWICE"
      | "CANCELLED"
      | "CLOSED"
  ) => {
    if (
      status === UserAccountStatus.INCOMPLETE &&
      this.profileAccountStatus === UserAccountStatus.INCOMPLETE
    ) {
      return true;
    }

    if (
      status === UserAccountStatus.APPROVED &&
      this.profileAccountStatus === UserAccountStatus.APPROVED
    ) {
      return true;
    }

    if (
      status === UserAccountStatus.PENDING &&
      this.profileAccountStatus === UserAccountStatus.PENDING
    ) {
      return true;
    }

    if (
      status === UserAccountStatus.DENIED &&
      this.profileAccountStatus === UserAccountStatus.DENIED
    ) {
      return true;
    }

    if (
      status === UserAccountStatus.SUSPENDED &&
      this.profileAccountStatus === UserAccountStatus.SUSPENDED
    ) {
      return true;
    }

    if (
      status === UserAccountStatus.SUSPENDED_TWICE &&
      this.profileAccountStatus === UserAccountStatus.SUSPENDED_TWICE
    ) {
      return true;
    }

    if (
      status === UserAccountStatus.CANCELLED &&
      this.profileAccountStatus === UserAccountStatus.CANCELLED
    ) {
      return true;
    }

    if (
      status === UserAccountStatus.CLOSED &&
      this.profileAccountStatus === UserAccountStatus.CLOSED
    ) {
      return true;
    }

    return false;
  };

  private prepareProfile = (
    profile: Profile,
    userRole: UserRole | null
  ): Profile => {
    const _profile: Profile = profile;
    if (userRole === "model") {
      (_profile as ModelProfile).grouped_star_ratings =
        this.addTotalAndAverageToGroupedStarRatings(
          (profile as ModelProfile)?.grouped_star_ratings
        );
    }

    return _profile;
  };

  private addTotalAndAverageToGroupedStarRatings = (
    ratings?: HashMap<number>
  ): HashMap<number> => {
    const defaultRatings: any = {
      ...EMPTY_MODEL_GROUPED_STAR_RATINGS,
      ...ratings,
    };

    let average = 0;

    //https://en.wikipedia.org/wiki/Weighted_arithmetic_mean
    Object.entries(defaultRatings)
      .filter(([key]) => key !== "count" && key !== "score")
      .forEach(([key, value]) => {
        defaultRatings.count = defaultRatings.count + value;
        average = average + parseInt(key) * parseInt(value as any);
      });

    defaultRatings.score = (average / defaultRatings.count).toFixed(1);

    return defaultRatings;
  };

  get liveModelProfile() {
    return {
      ...this.modelProfile,
      ...(this.modelProfile as ModelProfile)?.public_profile,
    };
  }

  get hasModelPendingProfileProperty() {
    return Object.entries(
      (this.modelProfile as ModelProfile)?.public_profile || []
    ).some(
      ([key, newValue]) =>
        (this.modelProfile as ModelProfile)?.[key] !== newValue
    );
  }

  public isModelProfilePropertySameOnLive = (key: string) => {
    return (this.modelProfile as ModelProfile)?.public_profile
      ? _.isEqual(
          this.modelProfile[key],
          this.modelProfile?.public_profile
            ? this.modelProfile?.public_profile[key]
            : ""
        )
      : true;
  };

  public updateProfileProperty = async (
    property: string,
    value: any,
    oldValue?: any
  ) => {
    const oldData = {
      property: property,
      value: oldValue ? oldValue : this.profile[property],
    };

    const isValid = validationStore.validate(property, value, {
      gender: this.profile.gender,
    });
    if (isValid) {
      this.loadingProfile = true;
      try {
        const { data } = await api
          .getMeEndpoint(authStore.userRole)
          .patch({ [property]: value });
        const newValue = data[property] !== undefined ? data[property] : value;
        this.updateProfileStoreProperty(property, newValue);
        snackbarStore.enqueueSnackbar({
          message: { id: "messages.success.profileUpdated" },
          variant: SnackbarVariants.SUCCESS,
          actions: [
            {
              callback: _data =>
                this.updateProfileProperty(_data.property, _data.value),
              parameters: oldData,
              labelId: "common.undo",
              defaultLabel: "Undo",
            },
          ],
        });
      } catch (e) {
        this.log("Model bio update error", config.showAjaxErrors ? e : "");
        validationStore.storeBackendErrors(e);
        snackbarStore?.enqueueSnackbar({
          message: { id: "error.bioCouldNotUpdated" },
          variant: SnackbarVariants.ERROR,
        });
      } finally {
        this.loadingProfile = false;
      }

      this.isProfileBioFieldsComplete = !!this.validateProfileBioFieldsComplete;
    }
  };

  public updateProfileStoreProperty = (property, value) => {
    if (property in this.profile) {
      if (Array.isArray(value)) {
        this.profile[property] = [...value];
      } else {
        this.profile[property] = value;
      }
    }
  };

  public getProfileCompletion = async () => {
    this.loadingProfileCompletion = true;
    try {
      const res = await api.modelMeProfileCompletion.get();
      this.profileCompletion = res.data;
    } catch (e) {
      this.log("getProfile Completion Error ", config.showAjaxErrors ? e : "");
    } finally {
      this.loadingProfileCompletion = false;
    }
  };

  public checkAccountApprovalStatus = () => {
    if (this.profile.status !== "APPROVED") {
      authStore.openRegisterModalToCompleteProfile(true);
    }
  };

  get isPendingTaxFormApproval() {
    return this.profile.tax_form?.tax_file &&
      this.profile.tax_form?.status === TaxFormStatus.PENDING
      ? true
      : false;
  }

  get isTaxFormDenied() {
    return this.profile.tax_form?.tax_file &&
      this.profile.tax_form?.status === TaxFormStatus.DENIED
      ? true
      : false;
  }

  get isPendingTaxFormUpload() {
    return !this.profile.tax_form?.tax_file ||
      this.profile.tax_form?.status === TaxFormStatus.DENIED
      ? true
      : false;
  }

  get validationFields() {
    const userRole = getUserRoleFromDomain();

    let fieldNames = MODEL_PROFILE_FIELDS_TO_BE_PATCHED[this.profile.gender];
    switch (userRole) {
      case "model":
        fieldNames = MODEL_PROFILE_FIELDS_TO_BE_PATCHED[this.profile.gender];
        break;
      case "studio":
        fieldNames = STUDIO_PROFILE_VALIDATION_FIELDS;
        break;
    }

    return fieldNames;
  }

  public updateProfile = async () => {
    try {
      this.loadingProfile = true;
      const reqParams = this.validationFields.reduce((params, property) => {
        if (property === "languages") {
          return {
            ...params,
            languages: this.profile[property].map(language => language.alpha_3),
          };
        } else if (property === "kinks") {
          return {
            ...params,
            kinks: this.profile[property].map(kink => kink.id),
          };
        } else if (property === "specialities") {
          return {
            ...params,
            specialities: this.profile[property].map(
              speciality => speciality.id
            ),
          };
        } else if (property === "attributes") {
          return {
            ...params,
            attributes: this.profile[property].map(attribute => attribute.id),
          };
        } else if (property === "fun_fact") {
          if (
            !this.isFunFactSubmitted &&
            this.modelProfile.fun_fact !== this.modelProfile.pending_fun_fact
          )
            return {
              ...params,
              fun_fact: this.modelProfile.pending_fun_fact,
            };
        }
        return { ...params, [property]: this.profile[property] };
      }, {});
      const { data } = await api
        .getMeEndpoint(authStore.userRole)
        .patch(reqParams);
      const toastMsg = this.isFunFactSubmitted
        ? "changesSubmittedForApproval"
        : "changesSaved";
      snackbarStore.enqueueSnackbar({
        message: { id: `messages.success.${toastMsg}` },
        variant: SnackbarVariants.SUCCESS,
      });
      if (data.pending_fun_fact !== this.modelProfile.pending_fun_fact)
        this.modelProfile.pending_fun_fact = data.pending_fun_fact;
      if (data.fun_fact !== this.modelProfile.fun_fact)
        this.modelProfile.fun_fact = data.fun_fact;
    } catch (e) {
      snackbarStore.enqueueSnackbar({
        message: { id: "error.bioCouldNotUpdated" },
        variant: SnackbarVariants.ERROR,
      });
    } finally {
      this.loadingProfile = false;
      this.isProfileBioFieldsComplete = !!this.validateProfileBioFieldsComplete;
      this.isFunFactSubmitted = false;
    }
  };

  public validateProfileFields = (fields: string[], values: Object) => {
    const fieldOptions: HashMap<ValidateOptions> = {};
    const fieldValues: HashMap<any> = {};
    fields.forEach(field => {
      fieldOptions[field] = {
        checkForEmptyString: true,
      };
      fieldValues[field] = values[field];
    });

    return validationStore.validateMultiple(
      fields,
      fieldValues,
      fieldOptions,
      false
    );
  };

  public get validateProfileBioFieldsComplete() {
    const userRole = getUserRoleFromDomain();
    switch (userRole) {
      case "model":
        return this.validateProfileFields(
          MODEL_PROFILE_FIELDS_TO_BE_PATCHED[this.profile.gender],
          this.profile
        );
      case "studio":
        return true;
    }
  }

  public saveProfile = async () => {
    await this.updateProfile();
  };

  public setFunFactSubmitted = (isFunFactSubmitted: boolean) => {
    this.isFunFactSubmitted = isFunFactSubmitted;
  };

  public getGifts = async () => {
    try {
      const res = await api.gifts.get();
      return res.data.data;
    } catch (e) {
      this.log("getGifts Error ", config.showAjaxErrors ? e : "");
    }
  };

  public uploadDocument = async (
    _documents: File[],
    _documentType: ProfileDocumentType
  ) => {
    const userRole = authStore.userRole || "model";
    const file = _documents[0];
    const { data } = await api
      .getSignDocumentUploadEndpoint(userRole)
      .post({ file_name: file.name });
    const {
      presigned_post: { post_url, form_data },
      view_url,
      key,
    } = data;
    const uploadData = createFormData(file, form_data);
    await axios.post(post_url, uploadData);
    return { view_url, key };
  };
  //onUploadProgress returns 100% before the request is actually done, so now it is changing depends on request's status.
  public uploadProfilePhoto = async (
    medias: File[],
    imageType: "profile_image" | "non_nude_profile_image"
  ) => {
    const file = medias[0];
    const randFn = (Math.random() * 1e20).toString(16) + ".png"; //BE requires a filename, if none is sent, create a random one (Temp solution for crop tool)
    const userRole = authStore.userRole;
    const { data } = await api
      .getMeEndpoint(userRole)
      .post({ file_name: file.name || randFn }, `sign-profile-image-upload/`);
    const {
      presigned_post: { post_url, form_data },
      view_url,
      key,
    } = data;
    const uploadData = createFormData(file, form_data);
    if (imageType === "profile_image") {
      this.profileImageProgress = 20;
    } else {
      this.nonNudeProfileImageProgress = 20;
    }
    const uploadConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    await axios.post(post_url, uploadData, uploadConfig).then(() => {
      if (imageType === "profile_image") {
        this.profileImageProgress = 100;
      } else {
        this.nonNudeProfileImageProgress = 100;
      }
    });
    const { data: updatedUser } = await api.getMeEndpoint(userRole).patch({
      [`${
        imageType === "profile_image"
          ? "profile_image"
          : "non_nude_profile_image"
      }_key`]: key,
    });
    const preparedProfile = this.prepareProfile(updatedUser as any, userRole);
    this.setProfile(preparedProfile, userRole);
    const profileAlbum = mediaManagerStore.profileAlbum;
    const updatedProfileAlbum = await mediaManagerStore.getAlbum(
      profileAlbum?.id as string
    );
    mediaManagerStore.manageAlbum(updatedProfileAlbum, "replace");
    return { view_url, key };
  };

  public setProfilePhoto = async (imgID: string) => {
    this.loadingProfile = true;
    const userRole = authStore.userRole;
    try {
      const imageType = this.isSettingNudeProfilePicture
        ? "profile_image"
        : "non_nude_profile_image";
      const res = await api.modelMeSetProfileImage.patch({
        [`${imageType}_key`]: imgID,
      });
      const toastMsg =
        imageType === "profile_image" ? "nudeProfilePic" : "nonNudeProfilePic";
      if (res.status !== 200) {
        snackbarStore.enqueueSnackbar({
          message: {
            id: "messages.error.setAnotherPicture",
            default: "Something went wrong. Please try again!",
          },
          variant: SnackbarVariants.ERROR,
        });
        return;
      }
      snackbarStore.enqueueSnackbar({
        message: { id: `messages.success.${toastMsg}` },
        variant: SnackbarVariants.SUCCESS,
      });
    } catch (err) {
      this.log("Failed to set profile image", err);
    } finally {
      const profileResponse = await api.getMeEndpoint(userRole as any).get();
      if (!!profileResponse) {
        const preparedProfile = this.prepareProfile(
          profileResponse.data,
          userRole
        );
        this.setProfile(
          preparedProfile,
          userRole,
          this.onBeforeProfileLoaded,
          this.onAfterProfileLoaded
        );
      }
      this.loadingProfile = false;
    }
  };

  public updateTaxFileKey = async (tax_file_key: string) => {
    const userRole = authStore.userRole;

    try {
      const { data: updatedUser } = await api.getMeEndpoint(userRole).patch({
        tax_form: {
          tax_file_key,
        },
      });
      const preparedProfile = this.prepareProfile(updatedUser as any, userRole);
      this.setProfile(preparedProfile, userRole);
      return preparedProfile?.tax_form.tax_file;
    } catch (error) {
      this.log("Failed to update tax file key ", error);
    }
  };

  public toggleAdmirerDetails = (memberId?: string) => {
    if (memberId && !this.activeAdmirerId) {
      this.activeAdmirerId = memberId;
    } else {
      this.activeAdmirerId = null;
    }
  };

  get geolocationCountry(): string {
    return (
      authStore.countries[this.profile?.last_login?.country || "US"] ||
      authStore.countries.US
    );
  }

  get geolocationIP(): string {
    return this.profile?.last_login?.ip || "";
  }

  setProfileInfoCompleted = (completed: boolean) => {
    this.profileInfoCompleted = completed;
  };
}
