import SockJS from "sockjs-client";
import { logger } from "library/core/utility";
import { ChatSocketMessage } from "./types";
import { authStore, logToGraylog, nodeChatStore, nodeChatToken } from "core/stores";
import { NODECHAT_CHAT_MODE } from "./consts";
import { ChatState } from "../chat/enums";
// TODO: Use Env
const CAMS_SOCKJS_SERVER = "https://camschat21.camsconnexion.com/chat";
const CONNEXION_SOCKJS_SERVER = "https://connexion.cams.com/connexion";
const CONNEXION_TOKEN = "aakcp25nw0";
// TODO: In favor of server side fetch token
const GUEST_TOKEN =
  "eyJhbGciOiJIUzI1NiJ9.eyJjb3VudHJ5IjoiSG9uZyBLb25nIiwic2l0ZSI6ImNhbXMiLCJoYW5kbGUiOiJndWVzdCIsInRzIjo1Njk5LCJjb3VudHJ5X2NvZGUiOiJISyIsInN0YXRlIjpudWxsLCJpc19ndWVzdCI6MX0.7e0EAshtz9gGynFeHBBo35Cu5GXFKaMv1h4wCYQ_Aas";
const GUEST_CREDENTAIL = {
  token: GUEST_TOKEN,
  un: "",
  pw: "",
};
import CookieStorage from "library/core/utility/cookie-storage";

const logPrefix = "[NodeChatSocket]:";

class NodeChatSocket {
  get server() {
    const chatServerFromCookie = CookieStorage.get("chatServer");
    return chatServerFromCookie || CAMS_SOCKJS_SERVER;
  }
  missedPongCount: number = 0;
  sock: any = null;
  connexionSock: any = null;
  streamName?: string = undefined;
  token = null;
  connexionJwt = null;
  currentModelStreamName: any = null;
  autoreconnect = true;
  lastError;
  reconnectTimeout;
  reconnectCount = 0;
  reconnectExponent = -1;
  reconnectMaxExponent = 14;
  ping_duration = 5 * 1000;
  pinger: any = null;
  events = {
    pong: [],
    user_cnt: [],
    message: [],
    room_topic: [],
    gift: [],
    tip: [],
    buzz: [],
    chat_info: [],
    member_muted: [],
    tipgift: [],
    whisper: [],
    whisper_price: [],
    fan_whisper: [],
    buzz_mode: [],
    update_user_info: [],
    wheel_game: [],
    wheel: [],
    spin: [],
    pending_paid: [],
    tip_progress: [],
    chat_type: [],
    member_joined: [],
    member_left: [],
    pleasure: [],
    model_quit: [],
    denied_access: [],
    banned: [],
    model_offline: [],
    kicked: [],
    private_setting: [],
    is_muted: [],
    user_connected: [],
    user_kicked: [],
    // Connexion
    connexion_auth: [],
    connexion_toyConnected: [],

    // tipping show
    curtain_down: [],
    tip_goal_reached: [],
    curtain_up: [],
    top_tipper: [],
    encoded_stream: [],

    //invite to private
    model_private_invite: [],

    // error
  };
  send_buffer = [];

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  constructor() {
    this.log("init nodechatsock")
  }

  initWithStreamName = async (streamName: string, chatType: NODECHAT_CHAT_MODE, isReconnecting: boolean) => {
    return this.openSocket(streamName, isReconnecting, chatType);
  };

  openConnexionSocket = () => {
    this.connexionSock = new SockJS(`${CONNEXION_SOCKJS_SERVER}`);
    this.connexionSock!.onopen = () => {
      this.connexionSock.isOpen = true;
      this.authConnexion();
    };
    this.connexionSock!.onmessage = this.connexionHandler;
    this.connexionSock!.onclose = () => {
      this.connexionSock.isOpen = false;
    };
  };

  private openSocket = async (
    streamName: string,
    isReconnecting: boolean = false,
    chatType?: NODECHAT_CHAT_MODE
  ):Promise<void> => {
    const { un, pw, token } = await this.fetchCredentials(isReconnecting);
    return new Promise((resolve, reject) => {
      this.log(
        "openSocket opening socket, got credentials",
        streamName,
        { un, pw, token },
        "prev soc open:",
        !!this.sock
      );
      if (!!this.sock) {
        this.log("openSocket found existing socket", this.sock);
        this.close("openSocket");
      }
      this.sock = new SockJS(this.server, null, {transports: ['websocket']});
      this.log(
        "openSocket NOT found existing socket, created anew",
        this.sock
      );
      this.streamName = streamName;
      Object.assign(this.sock, {
        onopen: () => {
          this.log(
            "openSocket onopen event. isReconnecting",
            isReconnecting
          );

          const extraParams = {
            reconnect: isReconnecting ? 1 : 0,
          };
          
          const isPartyOrPrivate = chatType === NODECHAT_CHAT_MODE.PARTY || chatType === NODECHAT_CHAT_MODE.PRIVATE;
          if (chatType && (!isPartyOrPrivate || !isReconnecting)) {
            extraParams["chatType"] = chatType;
          }

          this.authenticate(
            streamName,
            { un, pw, token },
            extraParams
          );
          this.reconnectCount = 0;
          this.reconnectExponent = -1;
          this.resetMissedPongCount();
          this.setPinger();
          nodeChatStore.connectionProblems = false;
          nodeChatStore.reconnectTime = 0;
          nodeChatStore.setChatState(ChatState.started);
          resolve();
        },
        onmessage: this.handler,
        onclose: e => {
          this.log("openSocket onclose event", e);
          if (
            e.code == 1002 ||
            ((e.code == 1006 || e.code == 2000 || e.code == 2010) &&
              this.autoreconnect &&
              this.streamName &&
              this.streamName === streamName)
          ) {
            logToGraylog('[NODECHAT]', "openSocket onclose event triggering reconnect. isReconnecting", {
              ...e,
              isReconnecting,
            });
            this.log(
              "openSocket onclose event triggering reconnect. isReconnecting",
              {
                ...e,
                isReconnecting,
              }
            );
            this.sock = null;
            this.reconnect(e);
          }
        },
        onerror: e => {
          reject(e);
          logToGraylog('[NODECHAT]', 'error connecting to nodechat socket', e);
        },
      });
    });
  };

  reconnect = (e) => {
    if (!!this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
    this.lastError = e;
    this.log("reconnect attempt", this.reconnectExponent, " received event", e);
    if (this.reconnectExponent < this.reconnectMaxExponent) {
      this.reconnectExponent += 1;
    }
    let delay = Math.ceil(Math.random() * ((1 << this.reconnectExponent) * 1000));
    if(delay > 15000) {
      delay = 15000;
    } else if(delay < 2000) {
      delay = 2000;
    }
    if(this.reconnectExponent > 3) {
      nodeChatStore.connectionProblems = true;
      nodeChatStore.reconnectTime = delay / 1000;
    }
    this.reconnectTimeout = setTimeout(
      async () => {
        try {
          await nodeChatStore.closeChatSocket(true);
        } catch (e) {}
        nodeChatStore.openChatSocket(true);
      },
      delay
    );
    this.reconnectCount++;
  };

  reconnectNow = async () => {
    if (!!this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
    try {
      await nodeChatStore.closeChatSocket(true);
    } catch (e) {}
    nodeChatStore.openChatSocket(true);
    this.reconnectCount++;
  }

  authenticate = (
    streamName,
    credentials: { un; pw; token } | {} = {},
    extraParams = {}
  ) => {
    const authParams: ChatSocketMessage = {
      ...this.genMessage("auth"),
      ...credentials,
      ...extraParams,
      stream: streamName?.toLowerCase() || "",
      qb: 0,
    };
    this.log("authenticate started", authParams);
    this.send(authParams);
  };

  fetchCredentials = async (isReconnecting: boolean) => {
    try {
      this.log(
        "fetchCredentials started ",
        authStore.isLoggedIn
      );
      let data: any = await nodeChatToken.get(
        "chatSocket.fetchCredentials",
        !isReconnecting
      );
      if (data) {
        this.token = data!.token as any;
      } else {
        data = GUEST_CREDENTAIL;
        this.token = data.token;
      }
      this.log("fetchCredentials returning data", data);
      return data;
    } catch (e) {
      this.log("fetchCredentials error retrieving token", e);
      const data: any = GUEST_CREDENTAIL;
      this.token = data.token;
      return data;
    }
  };

  sendMessage = message => {
    const msg: ChatSocketMessage = this.genMessage("message");
    msg.message = message;
    if (!!this.sock && this.sock.readyState == 1) {
      this.send(msg);
    } else {
      // TODO Implement Sock Buffer
    }
  };

  sendWhisper = (message, toHandle: string) => {
    const msg: ChatSocketMessage = this.genMessage("send_whisper");
    msg.message = message;
    msg.toHandle = toHandle;
    this.send(msg);
  };

  setChatType = (chatType: NODECHAT_CHAT_MODE) => {
    const msg: ChatSocketMessage = this.genMessage("set_chat_type");
    msg.value = chatType;
    this.send(msg);
  }

  setTipShow = (early: number, late: number, target: number) => {
    const msg: ChatSocketMessage = this.genMessage("set_tip_show");
    msg.early = early / 10;
    msg.late = late / 10;
    msg.target = target / 10;
    msg.sum = 0;
    this.send(msg);
  };

  setCurtain = (curtainDown: boolean) => {
    const msg: ChatSocketMessage = this.genMessage("curtain_down");
    msg.value = curtainDown ? 1 : 0;
    this.send(msg);
  }

  setAllowPrivate = (allow: boolean) => {
    let msg: ChatSocketMessage;
    if(allow) {
      msg = this.genMessage("allow_private");
    } else {
      msg = this.genMessage("no_private");
    }
    this.send(msg);
  }

  setMembersNeedBalanceToChat = (value: boolean) => {
    const msg: ChatSocketMessage = this.genMessage("model_mute_members");
    msg.value = value ? 1 : 0;
    this.send(msg);
  }

  setWhipserLayoutSettings = (value: boolean) => {
    const msg: ChatSocketMessage = this.genMessage("whispers_in_separate_tabs");
    msg.value = value ? 1 : 0;
    this.send(msg);
  }

  setResendUserList = () => {
    const msg = this.genMessage("resend_user_list");
    this.send(msg);
  }

  setBuzzMode = (enabled: boolean) => {
    const msg: ChatSocketMessage = this.genMessage("buzz_mode");
    msg.value = enabled ? 1 : 0;
    this.send(msg);
  }

  setFanWhisperFree = (enabled: boolean) => {
    const msg: ChatSocketMessage = this.genMessage("fan_whisper");
    msg.value = enabled ? 1 : 0;
    this.send(msg);
  }

  genMessage = action => {
    return {
      action: action,
      token: this.token,
    };
  };

  // Events
  subscribe = (name, callback) => {
    if (this.events[name]) {
      if(this.events[name].length > 0) {
        console.warn("DUPLICATE SUBSCRIBE", name)
      }
      this.events[name] = [callback];
    }
  };

  unsubscribe = (name) => {
    if (this.events[name]) {
      this.events[name] = []
    }
  };

  handler = ({ data }) => {
    const events = JSON.parse(data);
    events.forEach(event => {
      this.log("handler received event", event.action, event);
      if (event.action === 'pong') {
        // Reset missed pong count since we received a pong
        this.resetMissedPongCount();
      }
      this.trigger(event.action, event);
    });
  };

  checkMissedPongCount = () => {
    if (this.missedPongCount >= 4) {
      //this.logger.infoGraylog("checkMissedPongCount missed pong count reached 4, reconnecting to chat socket");
      this.log("checkMissedPongCount missed pong count reached 4, reconnecting to chat socket");
      // Clear pinger interval and reset missed pong count
      this.clearPinger();
      this.resetMissedPongCount();
      // Reconnect to socket after 4 missed pongs
      this.reconnect(this.lastError);
    }
  };

  resetMissedPongCount = () => {
    this.missedPongCount = 0;
  };

  trigger = (name, args) => {
    if (this.events[name]) {
      this.events[name].forEach(callback => {
        callback(args);
      });
    }
  };

  close = (origin?) => {
    return new Promise(resolve => {
      this.log("close started", !!this.sock?.close, origin);
      if (!!this.sock?.close) {
        this.sock.close();
      }
      this.sock = null;
      this.clearPinger();
      setTimeout(resolve, 100);
    });
  };

  setGetLastMsgs = () => {
    this.send(this.genMessage("get_last_msgs"));
  };

  checkUserInfo = () => {
    var msg: ChatSocketMessage = this.genMessage("check_user_info");
    this.send(msg);
  };

  updateMemberInfo = () => {
    var msg: ChatSocketMessage = this.genMessage("update_member_info");
    this.send(msg);
  };

  send = message => {
    if (!!this.sock) {
      this.sock?.send(JSON.stringify(message));
    }
  };

  setMemberMuted = (membername: string) => {
    var msg: ChatSocketMessage = this.genMessage("model_mute_member");
    msg.handle = membername;
    this.send(msg);
  }

  setMemberKicked = (membername: string) => {
    var msg: ChatSocketMessage = this.genMessage("kick");
    msg.toKick = membername;
    this.send(msg);
  }

  // Connexion
  authConnexion = () => {
    this.connexionSock!.send(
      JSON.stringify({
        action: "auth",
        token: CONNEXION_TOKEN,
        type: "remote",
        devices: "",
      })
    );
  };
  closeConnexionSocket = () => {
    if (this.connexionSock?.close) {
      this.connexionSock!.close();
    }
    this.connexionSock = null;
  };

  connexionHandler = ({ data }) => {
    const event = JSON.parse(data);
    this.log("connexionHandler received event", event.action, event);
    const action = `connexion_${event.action}`;
    this.trigger(action, event);
  };

  checkToyConnection = stream => {
    this.sendConnexion({
      action: "isToyConnected",
      stream,
    });
  };

  setConnexionJwt = jwt => {
    this.connexionJwt = jwt;
  };

  generateConnexionMessage = action => {
    return { action, jwt: this.connexionJwt };
  };

  sendConnexion = message => {
    this.connexionSock.send(JSON.stringify(message));
  };

  ping = () => {
    // Check missed pongs before sending ping
    this.checkMissedPongCount();
    // Send ping
    this.send(this.genMessage("user_ping"));
    // if (siteConfig.shouldCheckMissedPongsOnLegacyChat) {
    //   // Increment missed pongs
    //   this.missedPongCount++;
    // }
    this.missedPongCount++;
  };

  clearPinger = () => {
    if (!!this.pinger) {
      clearInterval(this.pinger);
    }
  };

  setPinger = (duration?: number) => {
    this.clearPinger();
    this.pinger = setInterval(this.ping, duration || this.ping_duration);
  };
}

export default NodeChatSocket;
