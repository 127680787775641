import React from "react";
import { inject, observer } from "mobx-react";
import BroadcastStore from "common/broadcast/_stores/broadcast/BroadcastStore";
import TailwindButton from "library/components/_tailwind/button";
import { BROADCAST_END_TIPPING_SHOW_EARLY_SHOW_STOP_WARNING_TIME_IN_MINUTES } from "common/broadcast/_stores/broadcast/consts";

type Props = {
  broadcastStore?: BroadcastStore;
};

const QAWidgetMisc: React.ComponentType<Props> = ({ broadcastStore }) => {
  const {
    endTippingShowEarlyShowStopWarningTimeInSeconds,
    setEndTippingShowEarlyShowStopWarningTimeInSeconds,
  } = broadcastStore!;

  return (
    <div>
      <TailwindButton
        backgroundColor={"primary"}
        onClick={() =>
          endTippingShowEarlyShowStopWarningTimeInSeconds === 10
            ? setEndTippingShowEarlyShowStopWarningTimeInSeconds(
                BROADCAST_END_TIPPING_SHOW_EARLY_SHOW_STOP_WARNING_TIME_IN_MINUTES *
                  60
              )
            : setEndTippingShowEarlyShowStopWarningTimeInSeconds(10)
        }>
        {endTippingShowEarlyShowStopWarningTimeInSeconds === 10 ? (
          <React.Fragment>
            Set tipping show early show stop (curtain down) warning time to the
            default of 5 minutes
          </React.Fragment>
        ) : (
          <React.Fragment>
            Set tipping show early show stop (curtain down) warning time to{" "}
            <b>10 seconds</b> (normally 5 minutes)
          </React.Fragment>
        )}
      </TailwindButton>
    </div>
  );
};

export default inject("broadcastStore")(observer(QAWidgetMisc));
