import React from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindBox from "library/components/_tailwind/box";
import {
  BROADCAST_MAX_ALLOWED_BITRATE_IN_KBPS,
  BROADCAST_MAX_ALLOWED_BITRATE_IN_MBPS,
  BROADCAST_MAX_ALLOWED_FRAME_RATE,
  BROADCAST_MAX_ALLOWED_RESOLUTION,
  BROADCAST_RECOMMENDED_BITRATE_IN_KBPS,
  BROADCAST_RECOMMENDED_BITRATE_IN_MBPS,
} from "common/broadcast/_stores/broadcast-stream/consts";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindAnchor from "library/components/_tailwind/anchor";
import TailwindHeading from "library/components/_tailwind/heading";
import TailwindDivider from "library/components/_tailwind/divider";
import {
  convertBpsToKbps,
  convertBpsToMbps,
  getReadableResolutionFromResolutionString,
} from "common/broadcast/_stores/broadcast-stream/utils";
import TailwindCard from "library/components/_tailwind/card";
import {
  BroadcastOBSVideoSettings,
  BroadcastOBSVideoSettingsValidation,
} from "common/broadcast/_stores/broadcast-stream/types";
import TailwindText from "library/components/_tailwind/text";

type Props = {
  obsVideoSettings: BroadcastOBSVideoSettings | null;
  obsSettingsValidation: BroadcastOBSVideoSettingsValidation;
};

const BroadcastInvalidObsSettingsError: React.ComponentType<Props> = ({
  obsVideoSettings,
  obsSettingsValidation,
}) => {
  const validIconComponent = (
    <TailwindIcon
      name={"check"}
      className={"text-green-500"}
      fontSize={"text-lg"}
    />
  );
  const invalidIconComponent = (
    <TailwindIcon
      name={"close"}
      className={"text-red-500"}
      fontSize={"text-lg"}
    />
  );

  const getIconComponent = (isValid: boolean) => {
    if (isValid) {
      return validIconComponent;
    }

    return invalidIconComponent;
  };

  return (
    <TailwindCard backgroundColor={"primary"}>
      <TailwindFlex
        alignItems={"items-center"}
        justifyContent={"justify-center"}
        padding={["p-4"]}>
        <TailwindFlex flexDirection={"flex-col"} width={"w-auto"}>
          <TailwindBox margin={["mb-4"]}>
            <TailwindText textColor='text-main-color'>
              Your video stream settings are higher than the recommended maximum
              values of:
            </TailwindText>
          </TailwindBox>
          <TailwindFlex
            width={"w-auto"}
            justifyContent={"justify-center"}
            flexDirection={"flex-col"}>
            <TailwindHeading level={6} textDecoration={"underline"}>
              Resolution
            </TailwindHeading>
            <TailwindFlex alignItems={"items-center"}>
              <TailwindText textColor='text-main-color'>
                <b>Your Resolution:</b>&nbsp;{obsVideoSettings?.resolution} (
                {getReadableResolutionFromResolutionString(
                  obsVideoSettings?.resolution!
                )}
                ) {getIconComponent(obsSettingsValidation.resolution)}
              </TailwindText>
            </TailwindFlex>
            <TailwindFlex alignItems={"items-center"}>
              <TailwindText textColor='text-main-color'>
                <b>Recommended/Max Resolution:</b>&nbsp;
                {BROADCAST_MAX_ALLOWED_RESOLUTION} (
                {getReadableResolutionFromResolutionString(
                  BROADCAST_MAX_ALLOWED_RESOLUTION
                )}
                ){" "}
              </TailwindText>
            </TailwindFlex>
          </TailwindFlex>
          <TailwindDivider margin={["my-2"]} />
          <TailwindFlex
            width={"w-auto"}
            justifyContent={"justify-center"}
            flexDirection={"flex-col"}>
            <TailwindHeading level={6} textDecoration={"underline"}>
              Frame Rate
            </TailwindHeading>
            <TailwindFlex alignItems={"items-center"}>
              <TailwindText textColor='text-main-color'>
                <b>Your Frame Rate:</b>&nbsp;{obsVideoSettings?.frameRate} FPS
                {getIconComponent(obsSettingsValidation.frameRate)}
              </TailwindText>
            </TailwindFlex>
            <TailwindFlex alignItems={"items-center"}>
              <TailwindText textColor='text-main-color'>
                <b>Recommended/Max Frame Rate:</b>&nbsp;
                {BROADCAST_MAX_ALLOWED_FRAME_RATE} FPS
              </TailwindText>
            </TailwindFlex>
          </TailwindFlex>
          <TailwindDivider margin={["my-2"]} />
          <TailwindFlex
            width={"w-auto"}
            justifyContent={"justify-center"}
            flexDirection={"flex-col"}>
            <TailwindHeading level={6} textDecoration={"underline"}>
              Bit Rate
            </TailwindHeading>
            <TailwindFlex alignItems={"items-center"}>
              <TailwindText textColor='text-main-color'>
                <b>Your Bit Rate:</b>&nbsp;
                {convertBpsToKbps(obsVideoSettings?.bitRate!).toFixed(0)}
                kbps ({convertBpsToMbps(obsVideoSettings?.bitRate!).toFixed(
                  2
                )}{" "}
                mbps)
                {getIconComponent(obsSettingsValidation.bitRate)}
              </TailwindText>
            </TailwindFlex>
            <TailwindFlex alignItems={"items-center"}>
              <TailwindText textColor='text-main-color'>
                <b>Recommended Bit Rate:</b>&nbsp;
                {BROADCAST_RECOMMENDED_BITRATE_IN_KBPS}
                kbps ({BROADCAST_RECOMMENDED_BITRATE_IN_MBPS} mbps)
              </TailwindText>
            </TailwindFlex>
            <TailwindFlex alignItems={"items-center"}>
              <TailwindText textColor='text-main-color'>
                <b>Max Bit Rate:</b>&nbsp;
                {BROADCAST_MAX_ALLOWED_BITRATE_IN_KBPS}
                kbps ({BROADCAST_MAX_ALLOWED_BITRATE_IN_MBPS} mbps)
              </TailwindText>
            </TailwindFlex>
          </TailwindFlex>
          <TailwindFlex alignItems={"items-center"} margin={["mt-6"]}>
            <TailwindText textColor='text-main-color'>
              Please lower your settings to continue on our site. For additional
              information visit our&nbsp;
              <TailwindAnchor
                to={
                  "https://help.streamray.com/models/knowledgebase/how-to-configure-open-broadcaster-software-obs-for-hd-broadcasting/"
                }>
                Help Center
              </TailwindAnchor>
              .
            </TailwindText>
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    </TailwindCard>
  );
};

export default BroadcastInvalidObsSettingsError;
