import axios, { rawAxios } from "core/config/axios";
import {
  broadcastStore,
  broadcastStreamStore,
  nodeChatSock,
  pricingStore,
  profileStore,
} from "core/stores";
import { SnackbarVariants } from "library/core/stores/snackbar/enums";
import { snackbarStore } from "library/core/stores/snackbar/SnackbarStore";
import { logger, uuid } from "library/core/utility";
import camsAudio from "library/core/utility/audio";
import _ from "lodash";
import { makeAutoObservable } from "mobx";
import {
  BroadcastStreamState,
  BroadcastType,
  IShowType,
} from "../broadcast/enums";
import { IChatLanguageTranslation } from "../chat/ChatSocket";
import {
  ChatModeEnum,
  ChatState,
  IChatTab,
  MemberActionEnum,
} from "../chat/enums";
import {
  IBufferedChatMessage,
  IChatMessage,
  IChatMessageOrNotification,
  IChatNotification,
  IMember,
  IMemberHash,
  IWhisperConversation,
  IWhisperHash,
  ITopAdmirers
} from "../chat/interfaces";
import { InitialData, NODECHAT_CHAT_MODE, NODECHAT_EVENTS } from "./consts";
import { MEMBER_SILENCE_STRING } from "../chat/consts";
import { decode } from "html-entities";
import { languageStore } from "library/core/stores/language/LanguageStore";
import moment from "moment";
import { MemberSortTypes, LovenseToyStatus } from "./types";
import { api } from "core/utility";
import CookieStorage from "library/core/utility/cookie-storage";

declare var CamExtension: any;

const { intl } = languageStore!;
const logPrefix = "[NodeChatStore]:";
class NodeChatStore {
  chatState: ChatState = InitialData.chatState;
  publicChatMessages: IChatMessage[] = InitialData.publicChatMessages;
  privateChatMessages: IChatMessage[] = InitialData.privateChatMessages;

  activeChatTab: IChatTab | string = InitialData.activeChatTab;
  isPrivateChatReady: boolean = InitialData.isPrivateChatReady;

  allMemberHash: IMemberHash = InitialData.allMemberHash;
  allTopAdmirerHash: ITopAdmirers[] = InitialData.allTopAdmirerHash;
  debounceTimeout: NodeJS.Timeout | null = null;

  publicMemberCount: number = InitialData.publicMemberCount;
  numberOfOnlineGuests: number = InitialData.numberOfOnlineGuests;
  numberOfOnlinePublicMembers: number = InitialData.numberOfOnlinePublicMembers;
  numberOfOnlinePrivateMembers: number =
    InitialData.numberOfOnlinePrivateMembers;

  privateWhisperHash: IWhisperHash = InitialData.privateWhisperHash;
  publicWhisperHash: IWhisperHash = InitialData.publicWhisperHash;
  bufferedMessages: IBufferedChatMessage[] = InitialData.bufferedMessages;
  giftHash: any = InitialData.giftHash;

  notifications: IChatNotification[] = InitialData.notifications;

  wheelOfFunSpinnerTimestamp: number = InitialData.wheelOfFunSpinnerTimestamp;
  wheelOfFunSpinnerUser: string = InitialData.wheelOfFunSpinnerUser;
  wheelOfFunSpinnerReward: string = InitialData.wheelOfFunSpinnerReward;
  currentChatLanguage: string = "en";
  showTippingSum: number = InitialData.showTippingSum;
  shouldShowPrivatememberLeftConfirmation: boolean =
    InitialData.shouldShowPrivatememberLeftConfirmation;
  fetchUserListTimeout: any = InitialData.fetchUserListTimeout;
  camExtension: any = InitialData.camExtension;
  camExtensionConnected: boolean = InitialData.camExtensionConnected;
  connectionProblems: boolean = InitialData.connectionProblems;
  reconnectTime: number = InitialData.reconnectTime;
  isReconnecting: boolean = InitialData.isReconnecting;
  memberSortType: MemberSortTypes = InitialData.memberSortType;
  sessionEarnings: number = InitialData.sessionEarnings;
  sessionPayoutPercent: number = InitialData.sessionPayoutPercent;
  activeMemberToWhisper: IMember | undefined = undefined;
  membersWhoWhispered: string[] = [];
  unreadMainChatMessages: number = 0;
  previousLovenseStatus: string = "";

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  constructor() {
    (window as any).nodeChatStore = this;
    makeAutoObservable(this);
    this.init();
  }

  init = async () => {
    await this.initGifts();
    this.setSessionPayoutPercentage();
  };

  checkAndInitLovense = () => {
    if (profileStore.modelProfile?.username) {
      this.initLovense();
    } else {
      setTimeout(this.checkAndInitLovense, 2000);
    }
  };

  initLovense = () => {
    console.log("lovense init for", profileStore.modelProfile?.username);
    const sitename = window.location.host.includes("prod")
      ? "Cams"
      : "ModelsQaCamsRun";
    this.camExtension = new CamExtension(
      sitename,
      profileStore.modelProfile?.username
    );
    this.camExtension.on("ready", this.lovenseOnReady);
    this.camExtension.on("toyStatusChange", this.lovenseToyStatusChange);
  };

  incrementSessionEarnings = (type: "tokens" | "dollars", amount: number) => {
    if (type === "tokens") {
      this.sessionEarnings =
        this.sessionEarnings + amount * 0.1 * this.sessionPayoutPercent;
    } else {
      this.sessionEarnings =
        this.sessionEarnings + amount * this.sessionPayoutPercent;
    }
  };

  lovenseToyStatusChange = (_data: LovenseToyStatus[]) => {
    if (_data && _data.length > 0) {
      _data.forEach(data => {
        if (data.status && this.previousLovenseStatus !== data.status) {
          const connStatusNotif = {
            id: uuid.getNew(),
            content: `Your ${data.type} toy ${
              data.name ? `'${data.name}'` : ""
            } is ${data.status === "on" ? "connected" : "disconnected"}.`,
            fixedContentKey: "broadcast.chatItem.lovenseStatus",
            fixedContentParams: {},
            created: new Date(),
          } as IChatNotification;
          this.addNotification(connStatusNotif);
          this.previousLovenseStatus = data.status;
        }

        const batteryLevels = [20, 10, 5];
        if (data.battery && batteryLevels.includes(data.battery)) {
          const batteryNotif = {
            id: uuid.getNew(),
            content: `Your ${data.type} toy ${
              data.name ? `'${data.name}'` : ""
            } battery is low at ${data.battery}%. Consider charging it.`,
            fixedContentKey: "broadcast.chatItem.lovenseBattery",
            fixedContentParams: {},
            created: new Date(),
          } as IChatNotification;
          this.addNotification(batteryNotif);
        }
      });
    }
  };

  lovenseOnReady = (ce: any) => {
    console.log("lovense onready", this.camExtension, ce);
    this.camExtensionConnected = true;
    const notif = {
      id: uuid.getNew(),
      content: "Lovense Extension initialized",
      fixedContentKey: "broadcast.chatItem.lovenseConnected",
      fixedContentParams: {},
      created: new Date(),
    } as IChatNotification;
    this.addNotification(notif);
  };

  initGifts = async () => {
    const { data } = await axios.get("/local-chat/gifts");
    this.log("gifts", data.results);
    if (data.results && data.results.length > 0) {
      data.results.forEach((gift: any) => {
        this.giftHash[gift.name] = gift;
      });
    }
  };

  setSessionPayoutPercentage = async (passed?: any) => {
    const fromCookie = CookieStorage.get("model_perc");
    if (passed && !isNaN(passed)) {
      CookieStorage.set("model_perc", passed);
      this.sessionPayoutPercent = parseFloat(passed);
    } else if (fromCookie && !isNaN(fromCookie)) {
      this.sessionPayoutPercent = parseFloat(fromCookie);
    }
  };

  public openChatSocket = async (isReconnecting: boolean = false) => {
    this.setChatState(ChatState.startingChat);
    try {
      await nodeChatSock.initWithStreamName(
        profileStore.modelProfile?.username,
        this.mapBOShowTypeToNodeChatMode(broadcastStore.currentShowType),
        isReconnecting
      );
      nodeChatSock.subscribe(NODECHAT_EVENTS.MESSAGE, this.onMessage);
      nodeChatSock.subscribe(NODECHAT_EVENTS.WHISPER, this.onWhisper);
      nodeChatSock.subscribe(
        NODECHAT_EVENTS.USER_COUNT,
        this.handleUserCountChange
      );
      nodeChatSock.subscribe(
        NODECHAT_EVENTS.MEMBER_JOINED,
        this.onMemberJoined
      );
      nodeChatSock.subscribe(
        NODECHAT_EVENTS.MEMBER_LEFT,
        this.handleMemberLeftChat
      );
      nodeChatSock.subscribe(NODECHAT_EVENTS.TIP, this.onMemberTip);
      nodeChatSock.subscribe(NODECHAT_EVENTS.GIFT, this.onMemberGift);
      nodeChatSock.subscribe(NODECHAT_EVENTS.WHEEL, this.onWheelResult);
      nodeChatSock.subscribe(NODECHAT_EVENTS.CHAT_TYPE, this.onChatType);
      nodeChatSock.subscribe(NODECHAT_EVENTS.CHAT_INFO, this.onChatInfo);
      nodeChatSock.subscribe(NODECHAT_EVENTS.TIP_PROGRESS, this.onTipProgress);
      nodeChatSock.subscribe(NODECHAT_EVENTS.USER_KICKED, this.onUserKicked);
      nodeChatSock.subscribe(NODECHAT_EVENTS.UPDATE_USER_INFO, this.onUpdateUserInfo);
      this.setChatState(ChatState.started);
      this.memberSortType = pricingStore.modelProducts.chat_settings
        .system_settings.member_sort_type
        ? (pricingStore.modelProducts.chat_settings.system_settings
            .member_sort_type as MemberSortTypes)
        : MemberSortTypes.TOP_SPENDER;
    } catch (e) {
      this.log("openChatSocket failed", e);
      this.setChatState(ChatState.errored);
    }
  };

  public closeChatSocket = async (isReconnecting: boolean = false) => {
    this.setChatState(ChatState.stoppingChat);
    nodeChatSock.unsubscribe(NODECHAT_EVENTS.MESSAGE);
    nodeChatSock.unsubscribe(NODECHAT_EVENTS.WHISPER);
    nodeChatSock.unsubscribe(NODECHAT_EVENTS.USER_COUNT);
    nodeChatSock.unsubscribe(NODECHAT_EVENTS.MEMBER_JOINED);
    nodeChatSock.unsubscribe(NODECHAT_EVENTS.MEMBER_LEFT);
    nodeChatSock.unsubscribe(NODECHAT_EVENTS.TIP);
    nodeChatSock.unsubscribe(NODECHAT_EVENTS.GIFT);
    nodeChatSock.unsubscribe(NODECHAT_EVENTS.WHEEL);
    nodeChatSock.unsubscribe(NODECHAT_EVENTS.CHAT_TYPE);
    nodeChatSock.unsubscribe(NODECHAT_EVENTS.CHAT_INFO);
    nodeChatSock.unsubscribe(NODECHAT_EVENTS.TIP_PROGRESS);
    nodeChatSock.unsubscribe(NODECHAT_EVENTS.USER_KICKED);
    nodeChatSock.unsubscribe(NODECHAT_EVENTS.UPDATE_USER_INFO);
    await nodeChatSock.close();

    if (!isReconnecting) {
      this.cleanupHashes();
    }

    this.setChatState(ChatState.stopped);
  };

  public mapBOShowTypeToNodeChatMode = (
    showType: IShowType
  ): NODECHAT_CHAT_MODE => {
    if (showType === IShowType.FREE) {
      return NODECHAT_CHAT_MODE.FREE;
    } else if (showType === IShowType.NUDE) {
      return NODECHAT_CHAT_MODE.NUDE;
    } else if (showType === IShowType.TIPPING) {
      return NODECHAT_CHAT_MODE.TIPPING;
    } else if (showType === IShowType.CURTAIN_DROPPED) {
      return NODECHAT_CHAT_MODE.TIPPING;
    } else if (showType === IShowType.PRIVATE) {
      return NODECHAT_CHAT_MODE.PRIVATE;
    } else if (showType === IShowType.ADMIN) {
      return NODECHAT_CHAT_MODE.ADMIN;
    } else {
      return NODECHAT_CHAT_MODE.FREE;
    }
  };

  onChatInfo = (_d: any) => {
    nodeChatSock.setGetLastMsgs();
    nodeChatSock.setFanWhisperFree(
      pricingStore.modelProducts.chat_settings.system_settings.fan_whisper_free
    );
    nodeChatSock.setMembersNeedBalanceToChat(
      pricingStore.modelProducts.chat_settings.system_settings
        .only_allow_members_with_fund_to_chat
    );
    nodeChatSock.setAllowPrivate(pricingStore.modelProducts.is_private_allowed);
    nodeChatSock.setBuzzMode(pricingStore.modelProducts.buzz_status === "on");
    nodeChatSock.setResendUserList();

    nodeChatSock.setWhipserLayoutSettings(
      pricingStore.modelProducts.chat_settings.system_settings
        .whispers_in_separate_tabs
    );
  };

  setFanWhisperFree = (bool: boolean) => {
    if (!!nodeChatSock.sock) {
      nodeChatSock.setFanWhisperFree(bool);
    }
  };

  setMembersNeedBalanceToChat = (bool: boolean) => {
    if (!!nodeChatSock.sock) {
      nodeChatSock.setMembersNeedBalanceToChat(bool);
    }
  };

  setAllowPrivate = (bool: boolean) => {
    nodeChatSock.setAllowPrivate(bool);
  };

  setResendUserList = () => {
    if (!this.fetchUserListTimeout) {
      this.fetchUserListTimeout = setTimeout(() => {
        nodeChatSock.setResendUserList();
        broadcastStore.reqeustSubscribedMembers();
        this.fetchUserListTimeout = null;
      }, 1000);
    }
  };

  setLanguage = async (language: IChatLanguageTranslation) => {
    this.log("setLanguage started");
    this.currentChatLanguage = language;
    this.log("setLanguage finished");
  };

  initTippingChat = () => {
    nodeChatSock.setTipShow(
      pricingStore.modelProducts?.chat_settings?.tipping?.earlyEntry,
      pricingStore.modelProducts?.chat_settings?.tipping?.lateEntry,
      pricingStore.modelProducts?.chat_settings?.tipping?.goal
    );
  };

  setCurtainDown = (curtainDown: boolean) => {
    nodeChatSock.setCurtain(curtainDown);
  };

  onMemberTip = (data: any) => {
    this.log("onMemberTip started", data);
    let broadcastType = BroadcastType.Tipped;
    const content = "";
    const image = "";
    const handle = data.handle.split("@")[0].toLowerCase();
    const isVoyeur =
      data.isVoyeur === 1 ||
      this.allMemberHash[data.handle.toLowerCase()].currentShowType ===
        IShowType.VOYEUR;
    const amount = (data.amount / 1) * 10 || 0;
    let fixedContentParams;
    let fixedContentKey = "broadcast.chatItem.tippedYouTkns";
    if (data.buzz === 1 && data.superbuzz === 1) {
      broadcastType = BroadcastType.SuperBuzz;
      fixedContentKey = intl.formatMessage(
        {
          id: `broadcast.chatItem.superBuzz`,
        },
        {
          member: handle,
          superBuzzPrice: amount,
          product: data.product,
          silence: data.isMuted ? MEMBER_SILENCE_STRING : "",
        }
      );
      fixedContentParams = {
        member: handle,
        chatfrom: handle,
        superBuzzPrice: amount,
        product: data.product,
        silence: data.isMuted ? MEMBER_SILENCE_STRING : "",
      };
    } else if (data.buzz === 1) {
      broadcastType = BroadcastType.Buzz;
      fixedContentKey = intl.formatMessage(
        {
          id: `broadcast.chatItem.buzz`,
        },
        {
          member: handle,
          buzzPrice: amount,
          product: data.product,
          silence: data.isMuted ? MEMBER_SILENCE_STRING : "",
        }
      );
      fixedContentParams = {
        member: handle,
        chatfrom: handle,
        buzzPrice: amount,
        product: data.product,
        silence: data.isMuted ? MEMBER_SILENCE_STRING : "",
      };
    } else {
      fixedContentParams = {
        chatfrom: handle,
        chatValue: amount,
        product: data.product,
      };
    }
    if (this.camExtension && this.camExtensionConnected) {
      this.camExtension.receiveTip(amount, handle);
    }
    this.checkAndSetMemberStatus(data.handle.toLowerCase());
    this.log("onMemberTip started", fixedContentParams);
    const notif = {
      id: uuid.getNew(),
      content,
      fixedContentKey,
      fixedContentParams,
      broadcastType,
      created: new Date(),
      image,
    } as IChatNotification;

    this.addNotification(notif);

    this.incrementSessionEarnings("dollars", data.amount / 1);

    this.fetchTopAdmirers();

    if (broadcastStore.isShowStarted && !isVoyeur) {
      if (data.superbuzz === 1) {
        camsAudio.playSuperBuzz();
      } else if (data.buzz === 1) {
        camsAudio.playBuzz();
      } else {
        camsAudio.playGreatIdea();
      }
    }
  };

  onMemberGift = (data: any) => {
    this.log("handleGiftSent started", data);
    const broadcastType = BroadcastType.Gift;
    const content = "";
    const image = this.giftHash[data.product]?.picture_name || "";
    const amount = (data.amount / 1) * 10 || 0;
    const handle = data.handle.split("@")[0].toLowerCase();
    const isVoyeur =
      data.isVoyeur === 1 ||
      this.allMemberHash[data.handle.toLowerCase()].currentShowType ===
        IShowType.VOYEUR;
    const fixedContentKey = intl.formatMessage(
      {
        id: `broadcast.chatItem.hasSentYouGift`,
      },
      {
        chatfrom: handle,
        chatValue: amount,
        product: data.product,
      }
    );
    const fixedContentParams = {
      chatfrom: handle,
      chatValue: amount,
      product: data.product,
    };

    const notif = {
      id: uuid.getNew(),
      content,
      fixedContentKey,
      fixedContentParams,
      broadcastType,
      created: new Date(),
      image,
    } as IChatNotification;

    this.addNotification(notif);

    this.checkAndSetMemberStatus(data.handle.toLowerCase());

    this.incrementSessionEarnings("dollars", data.amount / 1);

    if (this.camExtension && this.camExtensionConnected) {
      this.camExtension.receiveTip(amount, handle);
    }
    broadcastStore.isShowStarted && !isVoyeur && camsAudio.playGiftReceived();

    this.fetchTopAdmirers();

    this.log("handleGiftSent finished", data);
  };

  onWheelResult = (data: any) => {
    this.log("onWheelResult started", data);
    const broadcastType = BroadcastType.WheelOfFun;
    const username = data.handle.split("@")[0].toLowerCase();
    const isVoyeur =
      data.isVoyeur === 1 ||
      this.allMemberHash[data.handle.toLowerCase()].currentShowType === IShowType.VOYEUR;
    const fixedContentParams = {
      chatfrom: username,
      amount: (data.amount / 1) * 10 || 0,
      product: data.product,
    };

    const notif = {
      id: uuid.getNew(),
      fixedContentKey: intl.formatMessage(
        {
          id: `broadcast.chatItem.wheelOfFun`,
        },
        {
          chatfrom: `${username}`, 
          wheelReward: `${data.product}`,
          silence: data.isMuted ? MEMBER_SILENCE_STRING : "",
        }
      ),
      fixedContentParams,
      broadcastType,
      created: new Date(),
    } as IChatNotification;
    this.addNotification(notif);
    this.setWheelOfFunSpinner(username, data.product);
    this.incrementSessionEarnings("dollars", data.amount / 1);
    broadcastStore.isShowStarted && !isVoyeur && camsAudio.playFXGameWin2();
    this.fetchTopAdmirers();
    this.log("onWheelResult finished");
  };

  onTipProgress = (data: any) => {
    const sum = data?.sum * 10;
    broadcastStore.setShowTippingSum(sum);
  };

  setWheelOfFunSpinner = (wheelOfFunSpinnerUser, wheelOfFunSpinnerReward) => {
    this.log("setWheelOfFunSpinner started");
    this.wheelOfFunSpinnerTimestamp = new Date().getTime();
    this.wheelOfFunSpinnerUser = wheelOfFunSpinnerUser;
    this.wheelOfFunSpinnerReward = wheelOfFunSpinnerReward;
    this.log("setWheelOfFunSpinner finished");
  };

  addNotification = (notification: IChatNotification) => {
    this.log("addNotification started");
    const notif = { ...notification, created: new Date() };
    this.notifications = [notif, ...this.notifications];

    this.log("addNotification finished");
  };

  setChatState = (state: ChatState) => {
    this.log("setChatState started", state);
    this.chatState = state;

    if (this.chatState === ChatState.started) {
      if (
        typeof broadcastStreamStore.broadcastError === "object" &&
        broadcastStreamStore.broadcastError?.id ===
          "broadcast-error.stopping-stream"
      ) {
        broadcastStreamStore.setBroadcastError(null);
      }
    }

    this.log("setChatState finished");
  };

  onChatType = (data: any) => {
    this.log("chatype", data);
    if (data.type === NODECHAT_CHAT_MODE.FREE) {
    } else if (
      data.type === NODECHAT_CHAT_MODE.NUDE ||
      data.type === NODECHAT_CHAT_MODE.PRIVATE
    ) {
      this.setIsPrivateChatReady(true);
    }
  };

  addTimeRemainingMessageToChat = (handle, msg) => {
    const member = this.allMemberHash[handle];
    if (member.online && member.balance) {
      const costFactor =
        !!member.isFanClub && !!member.isPremiere
          ? 0.8
          : !!member.isFanClub || !!member.isPremiere
          ? 0.9
          : 1;
      let cost = 0;
      if (msg.data.payload.showType === IShowType.PRIVATE) {
        cost = pricingStore.modelProducts.private_chat;
      } else if (msg.data.payload.showType === IShowType.GROUP) {
        cost = pricingStore.modelProducts.party_chat;
      } else if (msg.data.payload.showType === IShowType.NUDE) {
        cost = pricingStore.modelProducts.nude_chat;
      } else if (msg.data.payload.showType === IShowType.VOYEUR) {
        cost = pricingStore.modelProducts.voyeur_chat;
      }
      if (broadcastStore.c2cHash[handle]) {
        cost += pricingStore.modelProducts.cam2cam_chat;
      }
      if (!cost) {
        return;
      }
      const minutesLeft = member.balance / (cost * costFactor);
      console.log("Verify time left", {
        rawCost: cost,
        costFactor: costFactor,
        cost: cost * costFactor,
        minutesLeft,
        handle,
        products: pricingStore.modelProducts,
      });
      let timeLeft = "";
      timeLeft = moment
        .utc()
        .startOf("day")
        .add({ minutes: minutesLeft })
        .format("H:mm:ss");

      const notif = {
        id: uuid.getNew(),
        created: new Date(),
        fixedContentKey: intl.formatMessage(
          {
            id: `notificationMessage.memberBalance`,
          },
          {
            member: `${member.username}`, 
            balance: `${timeLeft}`
          }
        ),
        fixedContentParams: {
          member: `${member.username}`,
          balance: `${timeLeft}`,
        },
      } as IChatNotification;
      this.addNotification(notif);
      
      const { fetchTimeDifference, previousPPM_Message } = broadcastStore;
      const timeDiffInSeconds = fetchTimeDifference;
      const sameTimeStamp = msg.data.fetchTime === previousPPM_Message?.data?.fetchTime;
      const sameShowType = msg.data?.payload?.showType === previousPPM_Message?.data?.payload?.showType;
      const sameUser = msg.data?.payload?.userName === previousPPM_Message?.data?.payload?.userName;
      const sameShowSession = msg.data?.payload?.showSession === previousPPM_Message?.data?.payload?.showSession;

      if ((sameUser && sameShowType && sameShowSession && sameTimeStamp) || (sameUser && sameShowType && sameShowSession && timeDiffInSeconds! < 60)) {
        return;
      } else {
        this.incrementSessionEarnings("tokens", cost * costFactor);
      }
    }
  };

  setChatType = (showType: IShowType) => {
    this.log("Set show type", showType);
    if (showType === IShowType.FREE) {
      nodeChatSock.setChatType(NODECHAT_CHAT_MODE.FREE);
    } else if (showType === IShowType.NUDE) {
      nodeChatSock.setChatType(NODECHAT_CHAT_MODE.NUDE);
    } else if (showType === IShowType.TIPPING) {
      nodeChatSock.setChatType(NODECHAT_CHAT_MODE.TIPPING);
    } else if (showType === IShowType.CURTAIN_DROPPED) {
      nodeChatSock.setChatType(NODECHAT_CHAT_MODE.TIPPING);
    } else if (showType === IShowType.PRIVATE) {
      nodeChatSock.setChatType(NODECHAT_CHAT_MODE.PRIVATE);
    } else if (showType === IShowType.ADMIN) {
      nodeChatSock.setChatType(NODECHAT_CHAT_MODE.ADMIN);
    }
  };

  reloadChat = (e: any) => {
    this.log("reloadChat started");
    nodeChatSock?.reconnect(e);
    this.log("reloadChat finished");
  };

  reconnectNow = () => {
    this.reconnectTime = 0;
    nodeChatSock.reconnectNow();
  };

  setActiveChatTab = (tab: IChatTab | string) => {
    this.log("setActiveChatTab started", tab);
    this.activeChatTab = tab;
    this.resetActiveWhisperChatMessageUnread();
    if (tab === IChatTab.PUBLIC_OR_PRIVATE) {
      this.resetPublicPrivateChatMessageUnread();
    }
    this.log("setActiveChatTab finished");
  };

  resetPublicPrivateChatMessageUnread = () => {
    this.log("resetActiveWhisperChatMessageUnread started");
    this.unreadMainChatMessages = 0;
    this.log("resetActiveWhisperChatMessageUnread finished");
  };

  resetActiveWhisperChatMessageUnread = () => {
    this.log("resetActiveWhisperChatMessageUnread started");
    if (this.whisperHash[this.activeChatTab]) {
      this.whisperHash[this.activeChatTab] = {
        ...this.whisperHash[this.activeChatTab],
        unread: 0,
      };
    }
    this.log("resetActiveWhisperChatMessageUnread finished");
  };

  onMessage = async (args: any) => {
    let text = args.message;
    const isModel =
      args.handle.toLowerCase() ===
      profileStore.modelProfile?.username.toLowerCase();
    if (this.currentChatLanguage !== "en" && !isModel) {
      text = await this.getTranslatedText(
        args.message,
        "en",
        this.currentChatLanguage
      );
    }
    if (!isModel) {
      const msg: IChatMessage = {
        from: args.handle,
        userId: args.handle,
        text,
        isModel,
        type: "text",
        created: new Date(),
        isMessageOrNotification: "message",
        isSentSuccessfully: true,
      };
      if (broadcastStore.isInPaidShow) {
        this.addPrivateChatMessage(msg);
      } else {
        this.addPublicChatMessage(msg);
      }
      if (this.activeChatTab !== IChatTab.PUBLIC_OR_PRIVATE) {
        this.unreadMainChatMessages++;
      }
      this.checkAndSetMemberStatus(args.handle.toLowerCase());
      if (this.camExtension && this.camExtensionConnected) {
        this.camExtension.receiveMessage(args.handle, text);
      }
    }
  };

  checkAndSetMemberStatus = async (handle: string, retries = 0) => {
    if (this.allMemberHash[handle]) {
      if (
        this.allMemberHash[handle].online === false ||
        this.allMemberHash[handle].online === null ||
        !this.allMemberHash[handle].currentShowType ||
        this.allMemberHash[handle].currentShowType !==
          broadcastStore.currentShowType
      ) {
        try {
          if (
            broadcastStore.currentShowType === IShowType.FREE ||
            broadcastStore.currentShowType === IShowType.TIPPING
          ) {
            this.allMemberHash[handle].currentShowType =
              broadcastStore.currentShowType;
            this.allMemberHash[handle].online = true;
          } else {
            const cleanUsername = handle.split("@")[0];
            const { data: session } =
              await broadcastStore.fetchMemberShowSession(cleanUsername).catch((e) => {
                throw new Error(e);
              });
            if (session && session.showType) {
              this.allMemberHash[handle].currentShowType = session.showType;
              if (
                session.showType === IShowType.VOYEUR ||
                session.showType === broadcastStore.currentShowType
              ) {
                this.allMemberHash[handle].online = true;
              }
            }
          }
        } catch (e) {
          this.log("checkAndSetMemberStatus failed, session not found");
          if(retries < 3) {
            const delay = Math.max(Math.floor(Math.random() * 4000), 1000);
            setTimeout(() => {
              this.checkAndSetMemberStatus(handle, retries + 1)
            }, delay) 
          }
        }
      }
    }
  };

  muteMember = (handle: string) => {
    this.log("muteMember started");
    nodeChatSock.setMemberMuted(handle);
    this.allMemberHash[handle].isInvisible = true;
    this.log("muteMember finished");
    snackbarStore.enqueueSimpleSnackbar(
      "messages.success.muteMember",
      SnackbarVariants.SUCCESS
    );
  };

  kickMember = (handle: string) => {
    this.log("kickMember started");
    nodeChatSock.setMemberKicked(handle);
    this.log("kickMember finished");
  };

  onUserKicked = async data => {
    const handle = data.handle.toLowerCase();
    this.log("onUserKicked start", handle);
    if (this.allMemberHash[handle]) {
      this.allMemberHash[handle].online = false;
      snackbarStore.enqueueSnackbar({
        message: {
          id: "messages.success.kickMember",
          parameters: {
            member: this.allMemberHash[handle].username,
          },
          default: `${this.allMemberHash[handle].username} has been kicked out.`,
        },
        variant: SnackbarVariants.SUCCESS,
      });
      this.log("onUserKicked finished", handle);
    }
  };

  unmuteMember = async (handle: string) => {
    this.log("unmuteMember started");
    nodeChatSock.setMemberMuted(handle);
    this.allMemberHash[handle].isInvisible = false;
    this.log("unmuteMember finished");
    snackbarStore.enqueueSimpleSnackbar(
      "messages.success.unmuteMember",
      SnackbarVariants.SUCCESS
    );
  };

  onMemberJoined = async data => {
    this.log("MEMBER JOINED CHAT", data);
    if (data.handle) {
      const handle = data.handle.toLowerCase();
      const username = handle.split(/@|\+/)[0];
      const topAdmirers = Object.keys(this.allTopAdmirerHash)
        .map(memberKey => ({
          ...this.allTopAdmirerHash[memberKey]
        }))
      const admirer = topAdmirers
        .find(member => member.handle === username) || [];
      const existingRank = data.admirerRank || null;
      const newRank = admirer.rank || 0;
      const rank = newRank === 0 ? existingRank : newRank;

      const member = {
        ...admirer,
        ...data.user,
        id: handle,
        username,
        isInvisible: data.isMuted === 1,
        isPremiere: data.premiere === 1,
        isFanClub: data.isFan === 1,
        rank,
        sort: data.memberPriority || 0,
        pwsid: data.uid,
        topAdmirer: rank,
        hasChatData: true,
        online: true,
        hasTokens: !!data.balance
      };

      this.allMemberHash[handle] = { ...this.allMemberHash[handle], ...member };

      if (
        this.allMemberHash[handle].currentShowType === IShowType.NONE ||
        this.allMemberHash[handle].currentShowType !==
          broadcastStore.currentShowType
      ) {
        this.checkAndSetMemberStatus(handle);
      }
      if (!this.allMemberHash[handle].timeJoined) {
        this.allMemberHash[handle].timeJoined = Date.now();
      }
    }
    this.handleUserCountChange(data);
    this.log("onMemberJoined finished");
  };

  onUpdateUserInfo = async data => {
    if(this.allMemberHash[data.handle]) {
      this.allMemberHash[data.handle].balance = (data.info.balance * 10);
      this.allMemberHash[data.handle].hasTokens = (data.info.balance > 0);
      this.allMemberHash[data.handle].isFanClub = (data.info.isFan === 1);
      this.allMemberHash[data.handle].isPremiere = (data.info.premiere === 1);
    }
  }

  fetchMemberShowSession = async (member: string) => {
    // just a test proxy, remove at some point
    broadcastStore.fetchMemberShowSession(member);
  };

  handleMemberJoinedBroadcast = (data: any) => {
    this.log("MEMBER JOINED BROADCAST", data);
    const {
      member_id,
      site,
      user_id,
      username,
      balance_in_tokens,
      registration_date,
      is_bounty,
      is_bounty_target,
      high_value,
      country,
    } = data;

    if (member_id && site && user_id && username) {
      const chatId = `${username}@cams`.toLowerCase();
      let newData;
      let regDate;
      if (registration_date) {
        const d = new Date(registration_date);
        regDate = d.valueOf();
      }
      const currentMemberData = this.allMemberHash[chatId];
      if (currentMemberData) {
        newData = {
          ...currentMemberData,
          member_id,
          site,
          user_id,
          hasBroadcastData: true,
          isBounty: is_bounty,
          isBountyTarget: is_bounty_target,
          isHighValue: high_value,
          online: !!currentMemberData.hasChatData,
          balance: balance_in_tokens || 0,
          country,
        };
      } else {
        newData = {
          id: chatId,
          username: username.split(/@|\+/)[0].toLowerCase(),
          member_id,
          isBounty: is_bounty,
          isBountyTarget: is_bounty_target,
          isHighValue: high_value,
          site,
          user_id,
          hasBroadcastData: true,
          online: null,
          balance: balance_in_tokens || 0,
          country,
        };
      }
      this.allMemberHash[chatId] = newData;
      if (regDate && !this.allMemberHash[chatId].registration_date) {
        this.allMemberHash[chatId].registration_date = regDate;
      }
      nodeChatSock.setResendUserList();
    }
  };

  handleMemberLeftChat = (data: any) => {
    this.log("MEMBER LEFT CHAT", data);
    const handle = data.handle.toLowerCase();
    if (this.allMemberHash[handle]) {
      this.allMemberHash[handle].hasChatData = false;
    }
    this.onMemberLeave({ handle: data.handle.toLowerCase() });
  };

  handleMemberLeftBroadcast = ({ site, username }) => {
    const handle = `${username}@cams`.toLowerCase();
    if (this.allMemberHash[handle]) {
      this.allMemberHash[handle].hasBroadcastData = false;
    }
    this.log("MEMBER LEFT BROADCAST", site, username);
    this.onMemberLeave({ handle });
  };

  startWhisperTo = (member: IMember) => {
    this.log("startWhisperTo started");
    if (
      !pricingStore?.modelProducts?.chat_settings.system_settings
        .whispers_in_separate_tabs &&
      member.username
    ) {
      this.activeMemberToWhisper = member;
      this.membersWhoWhispered = this.membersWhoWhispered.filter(
        username => username !== member.id
      );
      // To avoid creating tabs
      return;
    } else {
      const whisperHash = broadcastStore.isInPaidShow
        ? this.privateWhisperHash
        : this.publicWhisperHash;
      if (!whisperHash[member.id]) {
        whisperHash[member.id] = {
          messages: [],
          member,
          unread: 0,
          online: true,
        };
      }
      this.setActiveChatTab(member.id);
    }
    this.log("startWhisperTo finished");
  };

  toggleOffWhisperTo = (whisperId: string) => {
    if (
      !pricingStore?.modelProducts?.chat_settings.system_settings
        .whispers_in_separate_tabs &&
      this.activeMemberToWhisper?.username
    ) {
      // Remove active
      this.membersWhoWhispered = this.membersWhoWhispered.filter(
        username => username !== whisperId
      );
      this.activeMemberToWhisper = undefined;
    }
  };

  removeWhisper = (whisperId: string) => {
    this.log("removeWhisper started");
    const newPrivateWhisperHash = { ...this.privateWhisperHash };
    delete newPrivateWhisperHash[whisperId];
    this.privateWhisperHash = newPrivateWhisperHash;

    const newPublicWhisperHash = { ...this.publicWhisperHash };
    delete newPublicWhisperHash[whisperId];
    this.publicWhisperHash = newPublicWhisperHash;

    if (this.activeMemberToWhisper?.username === whisperId) {
      this.toggleOffWhisperTo(whisperId);
    }

    this.setActiveChatTab(IChatTab.PUBLIC_OR_PRIVATE);
    this.log("removeWhisper finished");
  };

  onMemberLeave = data => {
    this.log("onMemberLeave started");
    const handle = data.handle.toLowerCase();
    if (this.allMemberHash[handle]) {
      if (this.allMemberHash[handle].hasChatData === false) {
        this.allMemberHash[handle].online = false;
      }
    }

    this.handleUserCountChange(data);
    this.log("onMemberLeave finished");
  };

  playMemberEnterSoundForMember = (handle: string) => {
    if (
      pricingStore.modelProducts?.chat_settings?.system_settings
        ?.sound_enter_leave &&
      broadcastStore.streamState === BroadcastStreamState.started &&
      this.allMemberHash[handle]
    ) {
      camsAudio.playMemberEnter();
    }
  };

  playMemberLeaveSoundForMember = (handle: string) => {
    if (
      pricingStore.modelProducts?.chat_settings?.system_settings
        ?.sound_enter_leave &&
      broadcastStore.streamState === BroadcastStreamState.started &&
      this.allMemberHash[handle]
    ) {
      camsAudio.playMemberLeave();
    }
  };

  checkRemainingMembers = () => {
    if (
      broadcastStore.currentShowType === IShowType.PRIVATE ||
      broadcastStore.currentShowType === IShowType.GROUP
    ) {
      const remainingMembers = Object.keys(this.allMemberHash).filter(mk => {
        if (
          this.allMemberHash[mk].online === true &&
          (this.allMemberHash[mk].currentShowType === IShowType.PRIVATE ||
            this.allMemberHash[mk].currentShowType === IShowType.GROUP)
        ) {
          return true;
        }
        return false;
      }).length;
      const remainingVoys = Object.keys(this.allMemberHash).filter(vk => {
        if (
          this.allMemberHash[vk].online === true &&
          this.allMemberHash[vk].currentShowType === IShowType.VOYEUR
        ) {
          return true;
        }
        return false;
      }).length;

      if (remainingMembers === 0 && remainingVoys > 0) {
        this.setShouldShowPrivatememberLeftConfirmation(true);
      }
    }
  };

  get memberCount() {
    let count = 0;
    Object.keys(this.allMemberHash)
      .filter(
        m =>
          this.allMemberHash[m].currentShowType ===
            broadcastStore.currentShowType && !this.allMemberHash[m].isAdmin
      )
      .forEach(m => {
        const mem = this.allMemberHash[m];
        if (mem.online) {
          count++;
        }
      });
    Object.keys(this.allMemberHash)
      .filter(
        m =>
          this.allMemberHash[m].currentShowType === IShowType.VOYEUR &&
          !this.allMemberHash[m].isAdmin
      )
      .forEach(v => {
        const voy = this.allMemberHash[v];
        if (voy.online) {
          count++;
        }
      });
    this.log("memberCount returning", count);
    return count;
  }

  memberSortHighValue = (members: IMember[]): IMember[] => {
    return members.sort((a, b) => {
      if (a.isHighValue === !b.isHighValue) {
        return -1;
      }
      if (!a.isHighValue === b.isHighValue) {
        return 1;
      }
      return 0;
    });
  };

  memberSortAlpha = (members: IMember[]): IMember[] => {
    return members.sort((a, b) => {
      if (a.username < b.username) {
        return -1;
      }
      if (a.username > b.username) {
        return 1;
      }
      return 0;
    });
  };

  memberSortHighValueAlpha = (members: IMember[]): IMember[] => {
    const sortedByValue = this.memberSortHighValue(members);
    const highValueMembers = sortedByValue.filter(member => member.isHighValue);
    const notHighValueMembers = sortedByValue.filter(member => !member.isHighValue);
    const sortedHighValueMembers = this.memberSortAlpha(highValueMembers);
    const sortedNotHighValueMembers = this.memberSortAlpha(notHighValueMembers);
  
    return [...sortedHighValueMembers, ...sortedNotHighValueMembers];
  };
  
  memberSortTimeJoined = (members: IMember[]): IMember[] => {
    return members
      .sort((a, b) => {
        if (a.timeJoined < b.timeJoined) {
          return -1;
        }
        if (a.timeJoined > b.timeJoined) {
          return 1;
        }
        return 0;
      })
      .reverse();
  };

  memberSortRegDate = (members: IMember[]): IMember[] => {
    return members.sort((a, b) => {
      if (a.registration_date < b.registration_date) {
        return -1;
      }
      if (a.registration_date > b.registration_date) {
        return 1;
      }
      return 0;
    });
  };

  memberFilterSortAdmirer = (members: IMember[]): IMember[] => {
    return members
      .filter(mem => !!mem.topAdmirer)
      .sort((a, b) => {
        if (a.topAdmirer! < b.topAdmirer!) {
          return -1;
        }
        if (a.topAdmirer! > b.topAdmirer!) {
          return 1;
        }
        return 0;
      });
  };

  memberSortTopSpender = (members: IMember[]): IMember[] => {
    const topAdmirer: IMember[] = [];
    const bounty: IMember[] = [];
    const fanClub: IMember[] = [];
    const premiere: IMember[] = [];
    const spent1000: IMember[] = [];
    const spent250: IMember[] = [];
    const spent50: IMember[] = [];
    const hasTokens: IMember[] = [];
    const bountyTarget: IMember[] = [];
    const neverPurchased: IMember[] = [];
    const allElse: IMember[] = [];

    members.forEach(mem => {
      if (mem.topAdmirer) {
        topAdmirer.push(mem);
      } else if (mem.isBounty) {
        bounty.push(mem);
      } else if (mem.isFanClub) {
        fanClub.push(mem);
      } else if (mem.isPremiere) {
        premiere.push(mem);
      } else if (mem.spendingTier && mem.spendingTier >= 0.8) {
        spent1000.push(mem);
      } else if (mem.spendingTier && mem.spendingTier >= 0.6) {
        spent250.push(mem);
      } else if (mem.spendingTier && mem.spendingTier >= 0.4) {
        spent50.push(mem);
      } else if (mem.hasTokens) {
        hasTokens.push(mem);
      } else if (mem.isBountyTarget) {
        bountyTarget.push(mem);
      } else if (!mem.hasTokens) {
        neverPurchased.push(mem);
      } else {
        allElse.push(mem);
      }
    });

    return [
      ...this.memberFilterSortAdmirer(topAdmirer),
      ...this.memberSortHighValueAlpha(bounty),
      ...this.memberSortHighValueAlpha(fanClub),
      ...this.memberSortHighValueAlpha(premiere),
      ...this.memberSortHighValueAlpha(spent1000),
      ...this.memberSortHighValueAlpha(spent250),
      ...this.memberSortHighValueAlpha(spent50),
      ...this.memberSortHighValueAlpha(hasTokens),
      ...this.memberSortHighValueAlpha(bountyTarget),
      ...this.memberSortHighValueAlpha(neverPurchased),
      ...this.memberSortHighValueAlpha(allElse),
    ];
  };

  get members(): IMember[] {
    const _members = Object.keys(this.allMemberHash)
      .map(memberKey => ({
        ...this.allMemberHash[memberKey],
        rank: 100,
      }))
      .filter(member => member.currentShowType !== IShowType.VOYEUR)
      .filter(member => member.online)
      .filter(member => !member.isAdmin)
      .filter(
        member =>
          member.currentShowType &&
          member.currentShowType === broadcastStore.currentShowType
      );

    switch (this.memberSortType) {
      case MemberSortTypes.ALPHA:
        return this.memberSortAlpha(_members);
      case MemberSortTypes.TIME_JOINED:
        return this.memberSortTimeJoined(_members);
      case MemberSortTypes.REG_DATE:
        return this.memberSortRegDate(_members);
      case MemberSortTypes.TOP_SPENDER:
        return this.memberSortTopSpender(_members);
      default:
        return _members.sort((a, b) => {
          if (a.rank === 100 && b.rank === 100) {
            if (a.isFanClub && !b.isFanClub) {
              return -1;
            } else if (a.isBounty && !b.isBounty) {
              return -1;
            } else if (a.isPremiere && !b.isPremiere) {
              return -1;
            } else {
              return 0;
            }
          } else {
            return a.rank - b.rank;
          }
        });
    }
  }

  get voyeurMembers(): IMember[] {
    const members = Object.keys(this.allMemberHash)
      .filter(
        memberKey =>
          this.allMemberHash[memberKey].currentShowType === IShowType.VOYEUR
      )
      .filter(memberKey => this.allMemberHash[memberKey].online === true)
      .map(memberKey => ({
        ...this.allMemberHash[memberKey],
        rank: 100,
      }));
    this.log("voyeurMembers returning", members);
    return members;
  }

  handleUserCountChange = data => {
    this.log("handleUserCountChange started", data?.user_cnt);

    this.numberOfOnlineGuests = parseInt(data?.g) || 0;
    this.numberOfOnlinePublicMembers = parseInt(data?.m || 0);
    this.publicMemberCount =
      this.numberOfOnlineGuests + this.numberOfOnlinePublicMembers;
    this.log("handleUserCountChange finished");
  };

  addPublicChatMessage = (message: Omit<IChatMessage, "created">) => {
    this.log("addPublicChatMessage started");
    this.publicChatMessages = this.getMessagesWithNewMessageAdded(
      message,
      this.publicChatMessages
    );
    this.log("addPublicChatMessage finished");
  };

  addPrivateChatMessage = (message: Omit<IChatMessage, "created">) => {
    this.log("addPrivateChatMessage started");
    this.privateChatMessages = this.getMessagesWithNewMessageAdded(
      message,
      this.privateChatMessages
    );
    this.log("addPrivateChatMessage finished");
  };

  addWhisperChatMessage = (message: Omit<IChatMessage, "created">) => {
    this.log("addWhisperChatMessage started", message);
    this.membersWhoWhispered = [...this.membersWhoWhispered, message.from];
    const { from } = message;
    const whisperHash = broadcastStore.isInPaidShow
      ? this.privateWhisperHash
      : this.publicWhisperHash;
    const whisper = whisperHash[from];
    this.log("addWhisperChatMessage found", whisper);
    if (whisper) {
      whisper.messages = this.getMessagesWithNewMessageAdded(
        message,
        whisper.messages
      );
      whisper.unread =
        message.isModel || from === this.activeChatTab ? 0 : whisper.unread + 1;
    } else {
      whisperHash[from] = {
        messages: [{ ...message, created: new Date() } as IChatMessage],
        member: this.allMemberHash[from],
        unread: message.isModel ? 0 : 1,
        online: this.allMemberHash[from].online ? true : false,
      };
    }
    this.log("addWhisperChatMessage finished");
  };

  get unreadWhisperCount(): number {
    const whisperHash = broadcastStore.isInPaidShow
      ? this.privateWhisperHash
      : this.publicWhisperHash;
    let count = 0;
    Object.keys(whisperHash).forEach(member => {
      count += whisperHash[member].unread;
    });
    return count;
  }

  getMessagesWithNewMessageAdded = (
    message: Omit<IChatMessage, "created">,
    messages: IChatMessage[]
  ) => {
    const _message = {
      ...message,
      created: new Date(),
    };
    return [...messages, _message];
  };

  sortMessagesAndNotificationsByCreateDate = (
    messagesAndNotifications: IChatMessageOrNotification[]
  ) => {
    return messagesAndNotifications.sort((a, b) => {
      return a.created.getTime() - b.created.getTime();
    });
  };

  get publicChatMessagesAndNotifications(): IChatMessageOrNotification[] {
    return this.sortMessagesAndNotificationsByCreateDate([
      ...this.publicChatMessages,
      ...this.notifications,
    ]);
  }

  get privateChatMessagesAndNotifications(): IChatMessageOrNotification[] {
    return this.sortMessagesAndNotificationsByCreateDate([
      ...this.privateChatMessages,
      ...this.notifications,
    ]);
  }

  get whisperMembers(): Array<keyof IWhisperHash> {
    const members = Object.keys(this.whisperHash);
    this.log("whisperMembers returning", members);
    return members;
  }

  get whisperConversations(): IWhisperConversation[] {
    const _conversations = Object.values(this.whisperHash);
    const conversations = _conversations.filter(c => c?.member);
    const mappedConversations = conversations.map(conversation => {
      _.debounce(conversation => {
        const bufferedMessages = this.bufferedMessages.filter(
          m =>
            m.bufferChannelType === "whisper-private" ||
            (m.bufferChannelType === "whisper-public" &&
              m.toMemberId === conversation.member.id)
        );
        if (bufferedMessages.length > 0) {
          conversation.messages = this.sortMessagesAndNotificationsByCreateDate(
            [...conversation.messages, ...bufferedMessages]
          ) as IChatMessage[];
        }
      }, 100);

      conversation.online =
        this.allMemberHash?.[conversation.member.id]?.online || false;

      return conversation;
    });
    this.log("whisperConversations returning", mappedConversations);
    return conversations;
  }

  get whisperHash(): IWhisperHash {
    const hash = broadcastStore.isInPaidShow
      ? this.privateWhisperHash
      : this.publicWhisperHash;
    this.log("whisperHash returning", hash);
    return hash;
  }

  get numberOfOnlineMembers(): number {
    const count = Object.keys(this.allMemberHash).filter(
      member =>
        this.allMemberHash[member].online === true &&
        this.allMemberHash[member].currentShowType ===
          broadcastStore.currentShowType &&
        !this.allMemberHash[member].isAdmin
    ).length;
    this.log("numberOfOnlineMembers returning", count);
    return count;
  }

  get whisperUnread(): number {
    const count = Object.keys(this.whisperHash).reduce(
      (sum, whisper) => sum + this.whisperHash[whisper].unread,
      0
    );
    this.log("whisperUnread returning", count);
    return count;
  }

  onWhisper = async (args: any) => {
    const isModel =
      args.handle.toLowerCase() ===
      profileStore.modelProfile?.username.toLowerCase();
    let text = args.message;
    if (this.currentChatLanguage !== "en" && !isModel) {
      text = await this.getTranslatedText(
        args.message,
        "en",
        this.currentChatLanguage
      );
    }
    const msg: IChatMessage = {
      from: args.handle,
      userId: args.handle,
      text,
      isModel: isModel,
      type: "whisper",
      created: new Date(),
      isMessageOrNotification: "message",
      isSentSuccessfully: true,
    };

    if (isModel && this.activeMemberToWhisper) {
      msg["to"] = this.activeMemberToWhisper?.username;
    } else if (isModel && this.activeChatTab) {
      msg["to"] = this.activeChatTab?.split("@")[0];
    } else if (isModel) {
      msg["to"] = "member";
    }

    if (args.isAdmin) {
      if (this.allMemberHash[args.handle]) {
        this.allMemberHash[args.handle].isAdmin = true;
        this.allMemberHash[args.handle].hasBroadcastData = true;
        this.allMemberHash[args.handle].hasChatData = true;
        this.allMemberHash[args.handle].online = false;
      }
      msg.isAdmin = true;
      if (broadcastStore.isInPaidShow) {
        this.addPrivateChatMessage(msg);
      } else {
        this.addPublicChatMessage(msg);
      }
      this.addWhisperChatMessage(msg);
    } else {
      // add whisper to chat messages
      if (broadcastStore.isInPaidShow) {
        this.addPrivateChatMessage(msg);
      } else {
        this.addPublicChatMessage(msg);
      }

      if (!isModel) {
        this.checkAndSetMemberStatus(args.handle.toLowerCase());
      }

      if (!isModel) {
        // also add to whisper hash
        this.addWhisperChatMessage(msg);
        if (
          this.allMemberHash[args.handle] &&
          this.allMemberHash[args.handle].online === false
        ) {
          this.allMemberHash[args.handle].online = true;
        }
      }
    }
  };

  getTranslatedText = async (
    text: string,
    from: string,
    to: string,
    showOrig: boolean = true
  ) => {
    try {
      const response = await rawAxios.get(
        `https://www.googleapis.com/language/translate/v2?key=AIzaSyBVzPtx3Juy9kIhPIM4AvHKRjt7vN7SdLc&source=${from}&target=${to}&q=${text}`
      );
      const data = await response.data;
      if (data?.data?.translations?.length > 0) {
        return `${decode(
          data?.data?.translations[0].translatedText
        )} (auto-translated) ${
          showOrig ? `(Auto-Translated from: ${text})` : ""
        }`;
      } else {
        return text;
      }
    } catch (e) {
      console.log("Issue fetching translation, send original text");
      return text;
    }
  };

  sendMessage = async (
    _message: string,
    chatMode: ChatModeEnum,
    whisperMemberUsername?: string,
    whisperMemberId?: string
  ) => {
    try {
      this.log("sendMessage started");
      if (_message.trim() === "") {
        this.log("sendMessage cancel, empty message");
        return;
      }
      const isWhispering =
        chatMode === ChatModeEnum.WHISPER &&
        whisperMemberUsername &&
        whisperMemberUsername.trim() !== "";
      let message;
      if (this.currentChatLanguage !== "en") {
        message = await this.getTranslatedText(
          _message,
          this.currentChatLanguage,
          "en",
          false
        );
      } else {
        message = _message;
      }
      if (isWhispering && whisperMemberUsername && whisperMemberId) {
        const msg: IChatMessage = {
          from: whisperMemberId, // a little confusing, but this gets the message into the right tab
          userId: profileStore.modelProfile?.username,
          text: _message,
          isModel: true,
          type: "text",
          created: new Date(),
          isMessageOrNotification: "message",
          isSentSuccessfully: true,
        };
        this.addWhisperChatMessage(msg);
        nodeChatSock.sendWhisper(message, whisperMemberId);
      } else {
        const msg: IChatMessage = {
          from: profileStore.modelProfile?.username,
          userId: profileStore.modelProfile?.username,
          text: _message,
          isModel: true,
          type: "text",
          created: new Date(),
          isMessageOrNotification: "message",
          isSentSuccessfully: true,
        };
        if (broadcastStore.isInPaidShow) {
          this.addPrivateChatMessage(msg);
        } else {
          this.addPublicChatMessage(msg);
        }
        nodeChatSock.sendMessage(message);
      }
    } catch (e) {
      this.log("sendMessage error", e);
    }
  };

  updateMemberInfo = () => {
    nodeChatSock.updateMemberInfo();
  };

  submit = (chatInput: string) => {
    nodeChatSock.sendMessage(chatInput);
  };

  setIsPrivateChatReady = (isReady: boolean) => {
    this.log("setIsPrivateChatReady started", isReady);
    this.isPrivateChatReady = isReady;
    this.log("setIsPrivateChatReady finished");
  };

  handleNotifications = (
    message: any,
    broadcastType: BroadcastType,
    fixedContentKey: string,
    fixedContentParams?: any
  ) => {
    this.log("handleNotifications started");
    const event = {
      id: uuid.getNew(),
      broadcastType,
      created: new Date(message.startTime),
      fixedContentKey: `notificationMessage.${fixedContentKey}`,
      fixedContentParams,
    } as IChatNotification;

    this.addNotification(event);
    this.log("handleNotifications finished");
  };

  handleFavouredModel = data => {
    this.log("handleFavouredModel started");
    const event = {
      id: uuid.getNew(),
      created: new Date(),
      fixedContentKey: intl.formatMessage(
        {
          id: "notificationMessage.FavoredModel",
          defaultMessage: "Member {member} favorited you.",
        },
        {
          member: data.member_username,
        }
      ),
      fixedContentParams: {
        member: data.member_username,
      },
    } as IChatNotification;

    this.addNotification(event);
    this.log("handleFavouredModel finished");
  };

  handleBountyOrder = data => {
    this.log("handleBountyOrder started");
    const event = {
      id: uuid.getNew(),
      broadcastType: BroadcastType.BountyOrder,
      created: new Date(),
      fixedContentKey: intl.formatMessage(
        {
          id: "notificationMessage.memberBountyOrder",
          defaultMessage: "Woo-hoo! {member} just made his first purchase, so you now have a new Bounty. Congratulations! Please keep in mind that you'll earn additional bonuses on the spending made by {member} on you. Keep up the great work!",
        },
        {
          member: data.member_username,
        }
      ),
      fixedContentParams: {
        member: data.member_username,
      },
    } as IChatNotification;
    
    this.addNotification(event);
    broadcastStore.setBountyOrdered(data.member_username);
    this.log("handleBountyOrder finished");
  };

  handleFanClubOrder = data => {
    this.log("handleFanClubOrder started", data);
    if (data.product_type !== "fan_club_membership") return;
    this.addNotification({
      id: uuid.getNew(),
      broadcastType: BroadcastType.FanClubOrder,
      created: new Date(),
      fixedContentKey: intl.formatMessage(
        {
          id: "notificationMessage.memberFanClubOrder",
          defaultMessage: "Hey-ho! {member} just joined your Fan Club! Please be sure to give them special attention and appreciation for their support.",
        },
        {
          member: data.member_username,
        }
      ),
      fixedContentParams: {
        member: data.member_username,
      },
    } as IChatNotification);
    broadcastStore.setFanClubOrdered(data.member_username);
    this.log("handleFanClubOrder finished");
  };

  addMemberNotification = (
    member,
    action: MemberActionEnum,
    reason: string = ""
  ) => {
    this.log("addMemberNotification started");
    let chatType = "Private";
    switch (broadcastStore.currentShowType) {
      case IShowType.GROUP:
        chatType = "Party";
        break;
      case IShowType.NUDE:
        chatType = "Nude";
        break;
      case IShowType.CURTAIN_DROPPED:
      case IShowType.TIPPING:
        chatType = "Tipping";
        break;
      default:
        chatType = "Private";
    }
    const event = {
      id: uuid.getNew(),
      created: new Date(),
      fixedContentKey: `notificationMessage.member${action}${chatType}`,
      fixedContentParams: {
        member: member?.username,
        reason,
      },
    } as IChatNotification;
    this.addNotification(event);
    this.log("addMemberNotification finished");
  };

  handleVoyeurMembersJoined = (member: IMember) => {
    this.log("handleVoyeurMembersJoined started", member);
    const handle = member.username?.toLowerCase();
    if (this.allMemberHash[handle]) {
      this.allMemberHash[handle] = {
        ...(this.allMemberHash[handle] || {}),
        ...member,
        currentShowType: IShowType.VOYEUR,
      };
    }
    this.log("handleVoyeurMembersJoined finished");
  };

  handleVoyeurMembersLeft = (memberId: string) => {
    this.log("handleVoyeurMembersLeft started");
    if (this.allMemberHash[memberId]) {
      this.allMemberHash[memberId].online = false;
    }
    this.log("handleVoyeurMembersLeft finished");
  };

  handleMemberPrivateBlocked = (memberId: string) => {
    const memberName = this.allMemberHash[memberId]?.username || "A member";
    this.addPublicChatMessage({
      from: "System",
      text: intl.formatMessage(
        {
          id: "messages.memberPrivateBlocked",
          defaultMessage: `{memberName} just tried to take you private but was declined because of your setting to not accept private shows.`,
        },
        {
          memberName,
        }
      ),
      isModel: false,
      type: "text",
      isMessageOrNotification: "message",
      isSentSuccessfully: true,
    });
  };

  setShouldShowPrivatememberLeftConfirmation = (bool: boolean) => {
    this.shouldShowPrivatememberLeftConfirmation = bool;
  };

  cleanupHashes = () => {
    this.allMemberHash = {};
    this.publicWhisperHash = {};
    this.privateWhisperHash = {};
    this.publicChatMessages = [];
    this.privateChatMessages = [];
    this.notifications = [];
    this.resetPublicPrivateChatMessageUnread();
    this.sessionEarnings = 0;
  };

  setMemberSortType = async (type: MemberSortTypes) => {
    this.memberSortType = type;
    await api.pricing.patch({
      chat_settings: {
        system_settings: {
          member_sort_type: type,
        },
      },
    });
  };

  /**
   * fetchTopAdmirers:
   * This function fetches the latest top admirers from an external API,
   * debouncing the requests to avoid excessive API calls. It then updates 
   * the member list with the new ranks by calling updateMemberTopAdmirer.
   */

  fetchTopAdmirers = async () => {
    this.log("fetchTopAdmirers started");
    const { debounceTimeout } = this;
    try {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      this.debounceTimeout = setTimeout(async () => {
        this.debounceTimeout = null;
        const timestamp = Math.round(Date.now() / 10000) * 10000;     
        const { data: modelData } = await api.modelMe.get();
        const streamId = modelData?.imported_stream_id;
        const { data: admirers } = await api.activeTopAdmirers(streamId, timestamp).get();
        
        this.allTopAdmirerHash = admirers?.map(admirer => {
          return {
            handle: admirer.handle.toLowerCase(),
            uid: admirer.uid,
            rank: admirer.rank
          };
        });

        this.updateMemberTopAdmirer(admirers);
        this.log("fetchTopAdmirers ended", admirers);
      }, 1000); // Debounce time: 1 second

    } catch (error) {
      this.log("fetchTopAdmirers failed", error);
      this.allTopAdmirerHash = [];
    }
  };

  /**
   * updateMemberTopAdmirer:
   * This function updates the "top admirer" rank for each member 
   * based on the latest fetched admirer data.
   */

  updateMemberTopAdmirer = (admirers) => {
    this.log("updateMemberTopAdmirer started");
  
    this.members?.forEach(member => {
      const { username, id, topAdmirer, pwsid } = member;
      const correspondingAdmirer = admirers?.find(admirer => admirer.uid === pwsid);
      const correspondingAdmirerRank = correspondingAdmirer?.rank;
  
      if (!correspondingAdmirer || correspondingAdmirerRank == null) return;
  
      // Update the member's top admirer rank if the rank is between 1 and 5, or 6
      if ((correspondingAdmirerRank >= 1 && correspondingAdmirerRank <= 5) || correspondingAdmirerRank === 6) {
        if (correspondingAdmirerRank !== topAdmirer) {
          this.allMemberHash[id].topAdmirer = correspondingAdmirerRank;
        }
  
        // If the corresponding admirer rank is less than the current top admirer rank and <= 5, add a notification
        if (correspondingAdmirerRank < (topAdmirer ?? 0) && correspondingAdmirerRank <= 5) {
          this.addNotification({
            id: uuid.getNew(),
            created: new Date(),
            fixedContentKey: intl.formatMessage(
              {
                id: `broadcast.chatItem.topAdmirerEvent`,
                defaultMessage: `${username} has reached Top Admirer [${correspondingAdmirerRank}] status! Celebrate their unwavering admiration with praise and gratitude.`,
              },
              {
                member: username,
                chatValue: correspondingAdmirerRank
              }
            ),
            fixedContentParams: {
              member: username,
              chatfrom: username,
              chatValue: correspondingAdmirerRank,
            },
            broadcastType: BroadcastType.TopAdmirer,
          } as IChatNotification);
        }
      }
    });
  }
  

}

export default NodeChatStore;
