import React, { useCallback } from "react";
import TailwindFlex from "library/components/_tailwind/flex";
import { inject, observer } from "mobx-react";
import ChatStore from "../_stores/chat/ChatStore";
import EmojiPopover from "./chat-emoji-popover";
import TranslationPopover from "./chat-translation-popover";
import BroadcastChatFontSizePopover from "./chat-font-size-popover";
import TailwindButton from "library/components/_tailwind/button";
import { TFontSize } from "library/styles/tailwind-classnames";
import CustomIcon from "library/components/_tailwind/icon/icons";
import TailwindBox from "library/components/_tailwind/box";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";

type Props = {
  chatStore?: ChatStore;
  nodeChatStore?: NodeChatStore;
  inputRef: React.RefObject<HTMLTextAreaElement>;
};

const BroadcastChatInputOptions: React.ComponentType<Props> = ({
  chatStore,
  nodeChatStore,
  inputRef,
}) => {
  const { chatFontSize, setChatFontSize, message, setMessage } = chatStore!;
  const { currentChatLanguage } = nodeChatStore!;

  const addEmoji = useCallback(
    (emoji: string) => {
      const _ref = inputRef as any;
      const inputEl: HTMLInputElement = _ref.current;
      const selectionStart = inputEl.selectionStart;
      let newMessage = message;
      if (selectionStart !== null) {
        const selectionEnd = inputEl.selectionEnd;
        const messageFirstPart = message.substr(0, selectionStart);
        if (selectionEnd !== null) {
          const messageSecondPart = message.substr(
            selectionStart,
            message.length
          );
          newMessage = messageFirstPart + emoji + messageSecondPart;
        } else {
          newMessage = message + emoji;
        }
      }
      inputEl.focus();
      setMessage(newMessage);
    },
    [message, inputRef]
  );

  const onSetFont = (font: number | TFontSize) => {
    setChatFontSize(font as number);
  };

  return (
    <TailwindFlex gap={"gap-2"}>
      <EmojiPopover onSelectEmoji={addEmoji}>
        <TailwindButton
          dataTestId={"chat-emoji-button"}
          type={"button"}
          fullWidth={false}
          backgroundColor={"bg-secondary-bg-color"}
          borderRadius={"rounded-full"}
          leftIconProps={{
            name: "tag_faces",
          }}
        />
      </EmojiPopover>
      <TranslationPopover>
        <TailwindButton
          dataTestId={"chat-translation-button"}
          type={"button"}
          fullWidth={false}
          backgroundColor={"bg-secondary-bg-color"}
          borderRadius={"rounded-full"}>
          {currentChatLanguage !== "en" ? (
            <TailwindBox
              as={"span"}
              height={"h-4"}
              width={"w-4"}
              className={["fi", `fi-${currentChatLanguage}`]}
              borderRadius={"rounded-full"}
              backgroundSize={"bg-cover"}
              backgroundPosition={"bg-center"}
              backgroundRepeat={"bg-no-repeat"}
            />
          ) : (
            <CustomIcon name={"translate"} />
          )}
        </TailwindButton>
      </TranslationPopover>
      <BroadcastChatFontSizePopover
        fontSize={chatFontSize}
        onSetFont={onSetFont}>
        <TailwindButton
          dataTestId={"chat-font-size-button"}
          type={"button"}
          fullWidth={false}
          backgroundColor={"bg-secondary-bg-color"}
          borderRadius={"rounded-full"}
          leftIconProps={{
            name: "text_fields",
          }}
        />
      </BroadcastChatFontSizePopover>
    </TailwindFlex>
  );
};

export default inject("chatStore", "nodeChatStore")(observer(BroadcastChatInputOptions));
