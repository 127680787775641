import {
  BROADCAST_CAMERA_DEVICE_SELECTION_ALGORITHM_TYPE,
  BROADCAST_LAST_USED_CAMERA_DEVICE_ID_COOKIE_NAME,
  BROADCAST_LAST_USED_MICROPHONE_DEVICE_ID_COOKIE_NAME,
  BROADCAST_MAX_ALLOWED_BITRATE_IN_BPS,
  BROADCAST_MAX_ALLOWED_FRAME_RATE,
  BROADCAST_MAX_ALLOWED_RESOLUTION,
  BROADCAST_MICROPHONE_DEVICE_SELECTION_ALGORITHM_TYPE,
  WOWZA_DEFAULT_WEBRTC_SETTINGS,
} from "common/broadcast/_stores/broadcast-stream/consts";
import {
  BroadcastGatherMediaDeviceDataResponse,
  BroadcastOBSVideoSettings,
  BroadcastOBSVideoSettingsValidation,
  BroadcastPeerConnectionListeners,
  BroadcastVideoResolutionOption,
  BroadcastViewerCredentialsResponse,
  BroadcastWebRTCExtendedMediaDeviceInfo,
  BroadcastWebRTCPeerConnectionConfig,
  BroadcastWebRTCWebsocketListeners,
  NanoPlayerSettings,
  WowzaWebRTCStreamSettings,
} from "common/broadcast/_stores/broadcast-stream/types";
import { logger } from "library/core/utility";
import { BROADCAST_CAMERA_RESOLUTIONS } from "common/broadcast/_stores/broadcast/consts";
import CamsNanoPlayer from "core/utility/nano/nano";
import { api } from "core/utility";
import axios from "axios";
import config from "core/config";
import wowzaMungeSDP from "common/broadcast/_stores/broadcast-stream/WowzaMungeSDP";
import LayoutStore, {
  layoutStore,
} from "library/core/stores/layout/LayoutStore";
import { BroadcastDefaultDeviceSelectionAlgorithm } from "common/broadcast/_stores/broadcast-stream/enums";
import CookieStorage from "library/core/utility/cookie-storage";
import { broadcastStreamStore } from "core/stores";
import { parseResolutionString } from "common/broadcast/_stores/broadcast-stream/utils";

const logPrefix = "[BroadcastStreamService]:";

export class BroadcastStreamService {
  initializeWindowScope = () => {
    this.log("initializeWindowScope started");
    window.AudioContext =
      window.AudioContext || (window as any).webkitAudioContext || false;
    (window as any).DEFAULT_WOWZA_WEBRTC_SETTINGS =
      WOWZA_DEFAULT_WEBRTC_SETTINGS;
    this.log("initializeWindowScope finished");
  };

  getOBSSettingsValidation = (settings: BroadcastOBSVideoSettings | null) => {
    if (settings) {
      this.log("getOBSSettingsValidation started", settings);
      const { width: videoWidth, height: videoHeight } = parseResolutionString(
        settings?.resolution
      );
      const { width: maxVideoWidth, height: maxVideoHeight } =
        parseResolutionString(BROADCAST_MAX_ALLOWED_RESOLUTION);
      const hasLargerResolutionThanAllowed =
        videoHeight > maxVideoHeight || videoWidth > maxVideoWidth;
      const hasHigherFrameRateThanAllowed =
        settings?.frameRate > BROADCAST_MAX_ALLOWED_FRAME_RATE;
      const hasHigherBitRateThanAllowed =
        settings?.bitRate > BROADCAST_MAX_ALLOWED_BITRATE_IN_BPS;

      if (
        hasLargerResolutionThanAllowed ||
        hasHigherFrameRateThanAllowed ||
        hasHigherBitRateThanAllowed
      ) {
        this.log(
          "validateStreamStats received a video with higher width/height or frameRate or bitRate"
        );
        return {
          resolution: !hasLargerResolutionThanAllowed,
          frameRate: !hasHigherFrameRateThanAllowed,
          bitRate: !hasHigherBitRateThanAllowed,
        };
      }
    }

    // for WebRTC, we don't do checks so return all true
    return {
      resolution: true,
      frameRate: true,
      bitRate: true,
    };
  };

  getAreAllOBSSettingsValid = (
    settings: BroadcastOBSVideoSettingsValidation
  ) => {
    return Object.values(settings).every(s => s === true);
  };

  setMediaStreamTrackEnabled = (
    stream: MediaStream | null,
    trackKind: "video" | "audio",
    enabled: boolean
  ) => {
    this.log("setMediaStreamTrackEnabled started");
    if (stream != null && stream.getTracks != null) {
      stream.getTracks().map(track => {
        if (track.kind === trackKind) {
          track.enabled = enabled;
        }
      });
    }
    this.log("setMediaStreamTrackEnabled finished");
  };

  setSelectedCameraDevice = async (
    peerConnection: RTCPeerConnection | null,
    audioSender: RTCRtpSender | null,
    videoSender: RTCRtpSender | null,
    stream: MediaStream | null,
    cameraId: string
  ) => {
    this.log("setSelectedCameraDevice started");
    if (stream) {
      this.stopMediaStreamTracks(stream, "video");
      stream = await this.getUserMedia({
        video: {
          deviceId: cameraId,
        },
      });
      this.setLastUsedCameraDeviceIdToCookies(cameraId);
      const tracks = this.getMediaStreamVideoTracks(stream);
      if (tracks[0] && peerConnection && audioSender && videoSender) {
        await this.replaceTrack(
          peerConnection,
          audioSender,
          videoSender,
          "video",
          tracks[0]
        );
      }
    }
    this.log("setSelectedCameraDevice finished, returning stream");
    return stream;
  };

  replaceTrack = async (
    peerConnection: RTCPeerConnection | null,
    audioSender: RTCRtpSender | null,
    videoSender: RTCRtpSender | null,
    type: "audio" | "video",
    newTrack: MediaStreamTrack
  ) => {
    if (peerConnection != null) {
      if (type === "audio") {
        if (audioSender != null) {
          await audioSender.replaceTrack(newTrack);
        } else {
          audioSender = peerConnection.addTrack(newTrack);
        }
      } else if (type === "video") {
        if (videoSender != null) {
          this.log("Replacing track", newTrack);
          await videoSender.replaceTrack(newTrack);
        } else {
          this.log("Adding new track", newTrack);
          videoSender = peerConnection.addTrack(newTrack);
        }
      }
    }

    return {
      peerConnection,
      audioSender,
      videoSender,
    };
  };

  setSelectedMicrophoneDevice = async (
    peerConnection: RTCPeerConnection | null,
    audioSender: RTCRtpSender | null,
    videoSender: RTCRtpSender | null,
    stream: MediaStream | null,
    microphoneId: string
  ) => {
    const existingStreamVideoTracks = this.getMediaStreamVideoTracks(stream);
    if (stream) {
      this.log("setSelectedMicrophoneDevice started");
      const existingAudioTrack = this.getMediaStreamAudioTracks(stream)[0];
      stream = await this.getUserMedia({
        audio: {
          deviceId: microphoneId,
        },
      });
      this.setLastUsedMicrophoneDeviceToCookies(microphoneId);
      const tracks = broadcastStreamService.getMediaStreamAudioTracks(stream);
      if (tracks[0] && peerConnection && audioSender && videoSender) {
        await this.replaceTrack(
          peerConnection,
          audioSender,
          videoSender,
          "audio",
          tracks[0]
        );
        stream.removeTrack(existingAudioTrack);
      }
    }

    existingStreamVideoTracks.forEach(track => {
      stream?.addTrack(track);
    });

    this.log("setSelectedMicrophoneDevice finished, returning stream");
    return stream;
  };

  closePeerConnection = async (peerConnection: RTCPeerConnection | null) => {
    return new Promise(resolve => {
      this.log("closePeerConnection started");
      if (peerConnection) {
        peerConnection.close();
      }

      resolve();
      this.log("closePeerConnection finished");
    });
  };

  closeWebsocketConnection = async (websocketConnection: WebSocket | null) => {
    return new Promise(resolve => {
      this.log("closeWebsocketConnection started");
      if (websocketConnection) {
        websocketConnection.close();
      }
      resolve();
      this.log("closeWebsocketConnection finished");
    });
  };

  createWebsocketInstanceForWebRTC = (url: string): WebSocket | null => {
    this.log("connectToWebsocketsForWebRTC started");
    let connection: WebSocket | null = null;
    try {
      connection = new WebSocket(url);
      connection.binaryType = "arraybuffer";
      return connection;
    } catch (e) {
      this.log("connectToWebsocketsForWebRTC failed", e);
      return null;
    }
  };

  addWebsocketListeners = (
    connection: WebSocket | null,
    listeners: BroadcastWebRTCWebsocketListeners
  ): WebSocket | null => {
    if (connection) {
      Object.keys(listeners).forEach(key => {
        this.log("addWebsocketListeners adding listener", key);
        connection.addEventListener(key, listeners[key]);
      });
      return connection;
    } else {
      return null;
    }
  };

  createPeerConnectionInstanceForWebRTC = (
    config: BroadcastWebRTCPeerConnectionConfig
  ) => {
    const connection = new RTCPeerConnection(config);
    return connection;
  };

  addPeerConnectionListeners = (
    connection: RTCPeerConnection | null,
    listeners: BroadcastPeerConnectionListeners
  ) => {
    Object.keys(listeners).forEach(key => {
      connection?.addEventListener(key, listeners[key]);
    });
    return connection;
  };

  getAudioAndVideoSenderFromPeerConnection = (
    peerConnection: RTCPeerConnection | null,
    mediaStream: MediaStream | null
  ): { audioSender: RTCRtpSender | null; videoSender: RTCRtpSender | null } => {
    this.log("getAudioAndVideoSenderFromPeerConnection started");
    let audioSender: RTCRtpSender | null = null;
    let videoSender: RTCRtpSender | null = null;
    if (peerConnection && mediaStream) {
      const localTracks = mediaStream.getTracks();
      this.log(
        "getAudioAndVideoSenderFromPeerConnection found peerConnection and mediaStream, started adding local tracks to peerConnection"
      );
      for (const localTrack of localTracks) {
        const sender = peerConnection.addTrack(localTrack, mediaStream);
        this.log(
          "getAudioAndVideoSenderFromPeerConnection adding track",
          localTrack,
          "with kind",
          localTrack.kind
        );
        if (localTrack.kind === "audio") {
          audioSender = sender;
        } else if (localTrack.kind === "video") {
          videoSender = sender;
        }
      }
    } else {
      this.log(
        "getAudioAndVideoSenderFromPeerConnection could not find either peerConnection or mediaStream",
        peerConnection,
        mediaStream
      );
    }
    this.log("getAudioAndVideoSenderFromPeerConnection finished");
    return {
      audioSender,
      videoSender,
    };
  };

  createWebRTCOnStats(
    peerConnection: RTCPeerConnection,
    onStats: (report: RTCStatsReport) => void
  ) {
    return () => {
      if (peerConnection != null) {
        peerConnection.getStats(null).then(onStats, err => this.log(err));
      }
    };
  }

  createPeerNegotiationOffer = (
    peerConnection: RTCPeerConnection | null,
    websocketConnection: WebSocket | null,
    mungeData: any,
    webrtcSettings: WowzaWebRTCStreamSettings
  ) => {
    return new Promise((resolve, reject) => {
      this.log("createPeerNegotiationOffer started");
      if (
        peerConnection &&
        websocketConnection &&
        websocketConnection.readyState > 0
      ) {
        peerConnection?.createOffer(
          (description: any) => {
            this.log(
              "createPeerNegotiationOffer success, starting peerConnection.setLocalDescription"
            );

            if (!LayoutStore.isChromeVersion127OrAbove()) {
              description.sdp = wowzaMungeSDP(description.sdp, mungeData);
            }

            peerConnection
              .setLocalDescription(description)
              .then(() => {
                this.log(
                  "createPeerNegotiationOffer setLocalDescription success, sending websocket offer"
                );
                websocketConnection.send(
                  '{"direction":"publish", "command":"sendOffer", "streamInfo":' +
                    JSON.stringify(webrtcSettings.streamInfo) +
                    ', "sdp":' +
                    JSON.stringify(description) +
                    ', "userData":' +
                    JSON.stringify(webrtcSettings.userData) +
                    "}"
                );
                resolve();
                this.log(
                  "createPeerNegotiationOffer setLocalDescription finished"
                );
              })
              .catch(error => {
                reject(error);
              });
          },
          error => {
            reject(error);
          }
        );
      } else {
        reject();
      }
    });
  };

  setPeerConnectionRemoteDescription = async (
    peerConnection: RTCPeerConnection | null,
    sdpData: RTCSessionDescriptionInit
  ): Promise<RTCPeerConnection | null> => {
    return new Promise((resolve, reject) => {
      if (peerConnection) {
        peerConnection?.setRemoteDescription(
          new RTCSessionDescription(sdpData),
          () => {
            resolve(peerConnection);
          },
          error => {
            this.log("checkMessage setRemoteDescription failed", error);
            reject(error);
          }
        );
      } else {
        reject();
      }
    });
  };

  getIceCandidatesFromWebsocketResponse = (websocketResponse: any) => {
    this.log(
      "getIceCandidatesFromWebsocketResponse started",
      websocketResponse
    );
    this.log(
      "getIceCandidatesFromWebsocketResponse finished, returning iceCandidates"
    );
    return websocketResponse["iceCandidates"];
  };

  addIceCandidatesToPeerConnection = async (
    peerConnection: RTCPeerConnection | null,
    iceCandidates?: RTCIceCandidateInit[]
  ): Promise<RTCPeerConnection | null> => {
    return new Promise((resolve, reject) => {
      if (peerConnection) {
        if (iceCandidates !== undefined) {
          for (const index in iceCandidates) {
            this.log(
              "addIceCandidatesToPeerConnection adding ice candidate to peerConnection " +
                iceCandidates[index]
            );
            peerConnection.addIceCandidate(
              new RTCIceCandidate(iceCandidates[index])
            );
          }
          resolve(peerConnection);
        } else {
          this.log(
            "addIceCandidatesToPeerConnection failed because iceCandidates were undefined"
          );
          reject();
        }
      } else {
        this.log(
          "addIceCandidatesToPeerConnection failed because there was no peerConnection"
        );
        reject();
      }
    });
  };

  getSDPDataFromWebsocketResponse = (
    websocketResponse: any
  ): RTCSessionDescriptionInit | undefined => {
    const sdpData = websocketResponse["sdp"];
    this.log("getSDPDataFromWebsocketResponse received SDP data", sdpData.sdp);
    return sdpData;
  };

  applyMediaStreamConstraints = async (
    stream: MediaStream | null,
    constraints: MediaStreamConstraints
  ) => {
    this.log("applyMediaStreamConstraints started");
    if (!layoutStore.isFirefox) {
      const promises: Promise<any>[] = [];
      if (stream) {
        const audioTracks =
          broadcastStreamService.getMediaStreamAudioTracks(stream);
        const videoTracks =
          broadcastStreamService.getMediaStreamVideoTracks(stream);
        for (const a in audioTracks) {
          promises.push(
            audioTracks[a].applyConstraints(
              constraints.audio as MediaTrackConstraints
            )
          );
        }
        for (const v in videoTracks) {
          promises.push(
            videoTracks[v].applyConstraints(
              constraints.video as MediaTrackConstraints
            )
          );
        }
      }
      await Promise.all(promises);
    }
    this.log("applyMediaStreamConstraints finished, returning stream");
    return stream;
  };

  getMediaStreamAudioTracks = (
    stream: MediaStream | null
  ): MediaStreamTrack[] => {
    this.log("getMediaStreamAudioTracks started");
    const tracks = stream ? stream.getAudioTracks() : [];
    this.log("getMediaStreamAudioTracks finished, returning tracks");
    return tracks;
  };

  getMediaStreamVideoTracks = (
    stream: MediaStream | null
  ): MediaStreamTrack[] => {
    this.log("getMediaStreamVideoTracks started");
    const tracks = stream ? stream.getVideoTracks() : [];
    this.log("getMediaStreamVideoTracks finished, returning tracks");
    return tracks;
  };

  log = (...params: any[]) => {
    logger.log(logPrefix, ...params);
  };

  getUserMedia = async (
    constraints: MediaStreamConstraints
  ): Promise<MediaStream> => {
    return new Promise((resolve, reject) => {
      this.log("getUserMedia started", constraints);

      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((_stream: MediaStream) => {
            resolve(_stream);
          })
          .catch(error => {
            broadcastStreamStore.onErrorWebsocketForWebRTC(error, false);
            reject(error);
          });
      } else if (navigator.getUserMedia) {
        navigator.getUserMedia(
          constraints,
          (_stream: MediaStream) => {
            try {
              resolve(_stream);
            } catch (error) {
              broadcastStreamStore.onErrorWebsocketForWebRTC(error, false);
              reject(error);
            }
          },
          error => {
            broadcastStreamStore.onErrorWebsocketForWebRTC(error, false);
            reject(error);
          }
        );
      } else {
        broadcastStreamStore.onErrorWebsocketForWebRTC(
          {
            message: "Your browser does not support WebRTC",
          },
          false
        );
        this.log("getUserMedia failed, does not support WebRTC");
        reject();
      }
    });
  };

  stopMediaStreamTracks = (
    stream: MediaStream | null,
    trackTypeToStop: "all" | "video" | "audio" = "all"
  ) => {
    this.log("stopMediaStreamTracks started");
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => {
        if (
          trackTypeToStop === "all" ||
          (trackTypeToStop === "video" && track.kind === "video") ||
          (trackTypeToStop === "audio" && track.kind === "audio")
        ) {
          track.stop();
        }
      });
    }
    this.log("stopMediaStreamTracks finished");
  };

  getDoesCameraSupportResolution = async (
    resolutionString: string,
    deviceId: string
  ): Promise<boolean> => {
    try {
      this.log("getDoesCameraSupportResolution started for", resolutionString);
      const { width, height } = parseResolutionString(resolutionString);
      const stream = await this.getUserMedia({
        video: {
          width: { exact: width },
          height: { exact: height },
          deviceId: { exact: deviceId },
        },
      });
      const tracks = this.getMediaStreamVideoTracks(stream);

      let canSupport: boolean = false;

      tracks.forEach(track => {
        if (track.getCapabilities) {
          const capabilities = track.getCapabilities();
          if (capabilities.deviceId) {
            canSupport = true;
          }
        } else {
          const settings = track.getSettings();
          if (
            settings.deviceId &&
            settings.width === width &&
            settings.height === height
          ) {
            canSupport = true;
          }
        }
      });

      this.log(
        "getDoesCameraSupportResolution success for",
        resolutionString,
        deviceId
      );
      this.stopMediaStreamTracks(stream);
      return canSupport;
    } catch (err) {
      this.log(
        "getDoesCameraSupportResolution failed for",
        resolutionString,
        deviceId,
        "with error",
        err
      );
      if (
        err.name !== "OverconstrainedError" &&
        err.name !== "NotReadableError"
      ) {
        throw new Error(err);
      } else {
        return false;
      }
    } finally {
      this.log("getDoesCameraSupportResolution finished");
    }
  };

  getAvailableResolutionsForCamera = async (cameraId: string) => {
    const resolutions: BroadcastVideoResolutionOption[] = [];
    for await (const resolutionObj of BROADCAST_CAMERA_RESOLUTIONS) {
      const doesSupport = await this.getDoesCameraSupportResolution(
        resolutionObj.value,
        cameraId
      );
      if (doesSupport) {
        resolutions.push(resolutionObj);
      }
    }

    return resolutions;
  };

  getMaxResolutionCameraDeviceSupports = (
    resolutions: BroadcastVideoResolutionOption[]
  ) => {
    this.log("getMaxResolutionCameraDeviceSupports started");
    let maxResolution: string = "1280x720";
    maxResolution = resolutions.reduce((maxResolution, resolution) => {
      const { width: widthOnMaxResolution } =
        parseResolutionString(maxResolution);
      const { width } = parseResolutionString(resolution.value);
      if (width > widthOnMaxResolution) {
        return resolution.value;
      }
      return maxResolution;
    }, maxResolution);

    this.log(
      "getMaxResolutionCameraDeviceSupports finished, returning maxResolution",
      maxResolution
    );
    return maxResolution;
  };

  gatherMediaDeviceDataForQAPurposes = async () => {
    const cameras: BroadcastWebRTCExtendedMediaDeviceInfo[] = [];
    const microphones: BroadcastWebRTCExtendedMediaDeviceInfo[] = [];
    try {
      const devices = await navigator.mediaDevices?.enumerateDevices();
      devices.forEach(device => {
        if (device.kind === "videoinput") {
          // spread syntax does not work for device therefore use Object.assign
          const extendedDevice = Object.assign(device, {
            ...device,
            canBeUsed: true,
          });
          cameras.push(extendedDevice);
        } else if (device.kind === "audioinput") {
          const extendedDevice = Object.assign(device, {
            ...device,
            canBeUsed: true,
          });
          microphones.push(extendedDevice);
        }
      });
    } catch (error) {}

    return {
      cameras,
      microphones,
    };
  };

  getLastUsedCameraDeviceIdFromCookies = (): string | undefined => {
    return CookieStorage.get(BROADCAST_LAST_USED_CAMERA_DEVICE_ID_COOKIE_NAME);
  };

  setLastUsedCameraDeviceIdToCookies = (deviceId: string) => {
    this.log("setLastUsedCameraDeviceIdToCookies started", deviceId);
    const tenYearsAfterToday = new Date();
    tenYearsAfterToday.setFullYear(new Date().getFullYear() + 10);
    CookieStorage.set(
      BROADCAST_LAST_USED_CAMERA_DEVICE_ID_COOKIE_NAME,
      deviceId,
      {
        expires: tenYearsAfterToday,
      }
    );
    this.log("setLastUsedCameraDeviceIdToCookies finished");
  };

  getLastUsedMicrophoneDeviceIdFromCookies = (): string | undefined => {
    return CookieStorage.get(
      BROADCAST_LAST_USED_MICROPHONE_DEVICE_ID_COOKIE_NAME
    );
  };

  setLastUsedMicrophoneDeviceToCookies = (deviceId: string) => {
    this.log("setLastUsedMicrophoneDeviceToCookies started", deviceId);
    const tenYearsAfterToday = new Date();
    tenYearsAfterToday.setFullYear(new Date().getFullYear() + 10);
    CookieStorage.set(
      BROADCAST_LAST_USED_MICROPHONE_DEVICE_ID_COOKIE_NAME,
      deviceId,
      {
        expires: tenYearsAfterToday,
      }
    );
    this.log("setLastUsedMicrophoneDeviceToCookies finished");
  };

  gatherMediaDeviceData = async (
    excludedDevices: BroadcastWebRTCExtendedMediaDeviceInfo[]
  ): Promise<BroadcastGatherMediaDeviceDataResponse> => {
    this.log("gatherMediaDeviceData started");
    const cameras: BroadcastWebRTCExtendedMediaDeviceInfo[] = [];
    const microphones: BroadcastWebRTCExtendedMediaDeviceInfo[] = [];
    let selectedCameraDeviceId,
      selectedMicrophoneDeviceId,
      maxResolution,
      resolutions;
    try {
      if (layoutStore.isFirefox) {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      }
      const devices = await navigator.mediaDevices?.enumerateDevices();
      const filteredDevices = devices?.filter(device => {
        const excludedDevice = excludedDevices.find(
          _device => _device.deviceId === device.deviceId
        );
        if (excludedDevice) {
          return false;
        }

        return true;
      });

      const lastUsedCameraDeviceId =
        this.getLastUsedCameraDeviceIdFromCookies();
      const lastUsedMicrophoneDeviceId =
        this.getLastUsedMicrophoneDeviceIdFromCookies();

      for await (const device of filteredDevices) {
        if (device.kind === "videoinput") {
          const resolutionsDeviceSupport =
            await this.getAvailableResolutionsForCamera(device.deviceId);
          // spread syntax does not work for device therefore use Object.assign
          const extendedDevice = Object.assign(device, {
            canBeUsed: resolutionsDeviceSupport.length > 0,
          });
          cameras.push(extendedDevice);
          if (resolutionsDeviceSupport.length) {
            const maxResolutionCameraDeviceSupports =
              this.getMaxResolutionCameraDeviceSupports(
                resolutionsDeviceSupport
              );

            const parsedMaxResolution =
              maxResolution !== undefined
                ? parseResolutionString(maxResolution)
                : undefined;
            const parsedSupportedResolution = parseResolutionString(
              maxResolutionCameraDeviceSupports
            );

            const hasCameraBetterResolutionThanCurrentMaxResolution =
              !maxResolution ||
              (parsedMaxResolution &&
                parsedSupportedResolution.width > parsedMaxResolution.width);

            if (hasCameraBetterResolutionThanCurrentMaxResolution) {
              maxResolution = maxResolutionCameraDeviceSupports;
            }

            if (
              (BROADCAST_CAMERA_DEVICE_SELECTION_ALGORITHM_TYPE ===
                BroadcastDefaultDeviceSelectionAlgorithm.BEST_AVAILABLE &&
                hasCameraBetterResolutionThanCurrentMaxResolution) ||
              (BROADCAST_CAMERA_DEVICE_SELECTION_ALGORITHM_TYPE ===
                BroadcastDefaultDeviceSelectionAlgorithm.LAST_USED &&
                (device.deviceId === lastUsedCameraDeviceId ||
                  !lastUsedCameraDeviceId ||
                  lastUsedCameraDeviceId === "undefined"))
            ) {
              if (
                device.label !== "OBS Virtual Camera" &&
                filteredDevices.length > 1
              ) {
                selectedCameraDeviceId = device.deviceId;
              } else if (filteredDevices.length === 1) {
                selectedCameraDeviceId = device.deviceId;
              }
            }

            if (!resolutions) {
              resolutions = [];
            }

            resolutionsDeviceSupport.forEach(resolution => {
              const existingResolution = resolutions?.find(
                _resolution => _resolution.value === resolution.value
              );
              if (existingResolution) {
                existingResolution.deviceIds.push(device.deviceId);
              } else {
                resolutions.push({
                  ...resolution,
                  deviceIds: [device.deviceId],
                });
              }
            });
          }
        } else if (device.kind === "audioinput") {
          // spread syntax does not work for device therefore use Object.assign
          const extendedDevice = Object.assign(device, { canBeUsed: true });
          microphones.push(extendedDevice);
          if (
            BROADCAST_MICROPHONE_DEVICE_SELECTION_ALGORITHM_TYPE ===
              BroadcastDefaultDeviceSelectionAlgorithm.FIRST_AVAILABLE ||
            (BROADCAST_MICROPHONE_DEVICE_SELECTION_ALGORITHM_TYPE ===
              BroadcastDefaultDeviceSelectionAlgorithm.LAST_USED &&
              (lastUsedMicrophoneDeviceId === device.deviceId ||
                !lastUsedMicrophoneDeviceId ||
                lastUsedMicrophoneDeviceId === "undefined"))
          ) {
            selectedMicrophoneDeviceId = device.deviceId;
          }
        }
      }

      this.log("gatherMediaDeviceData success");
    } catch (err) {
      this.log("gatherMediaDeviceData failed", err);
      throw new Error(err);
    } finally {
      this.log("gatherMediaDeviceData finished");
    }
    return {
      resolutions,
      maxResolution,
      cameras,
      microphones,
      selectedCameraDeviceId,
      selectedMicrophoneDeviceId,
    };
  };

  getMediaStreamForParticularDeviceId = async (
    deviceType: "audio" | "video",
    deviceId: string
  ): Promise<MediaStream> => {
    return new Promise((resolve, reject) => {
      this.getUserMedia({
        [deviceType]: {
          advanced: [
            {
              deviceId,
            },
          ],
        },
      })
        .then(stream => {
          resolve(stream);
        })
        .catch(reject);
    });
  };

  stopCamerasAndMicrophones = async (
    cameras: MediaDeviceInfo[],
    microphones: MediaDeviceInfo[]
  ) => {
    for await (const camera of cameras) {
      const stream = await this.getMediaStreamForParticularDeviceId(
        "video",
        camera.deviceId
      );
      this.stopMediaStreamTracks(stream);
    }
    for await (const microphone of microphones) {
      const stream = await this.getMediaStreamForParticularDeviceId(
        "audio",
        microphone.deviceId
      );
      this.stopMediaStreamTracks(stream);
    }
  };

  getMediaStreamWithAllMediaDevices = async (): Promise<MediaStream> => {
    return new Promise((resolve, reject) => {
      this.getUserMedia({
        video: true,
        audio: true,
      })
        .then(stream => {
          this.log(
            "getAllMediaDevicesAndThenStopMediaStreamTracks success",
            stream.getTracks()
          );
          resolve(stream);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          this.log("getAllMediaDevicesAndThenStopMediaStreamTracks finished");
        });
    });
  };

  getAdminCredentials = async (
    adminName: string,
    channelName: string,
    modelProfileId: string
  ) => {
    try {
      this.log("getAdminCredentials started");
      const { data } = await api.broadcastAdmins.get({
        pathParams: `${adminName}/${channelName}/${modelProfileId}`,
      });
      this.log("getAdminCredentials success", data);
      return data;
    } catch (error) {
      this.log("getAdminCredentials failed", error);
    }
  };

  getViewerCredentials = async (
    modelName: string,
    channelName: string,
    modelProfileId: string
  ): Promise<BroadcastViewerCredentialsResponse | undefined> => {
    try {
      this.log("getViewerCredentials started");
      const { data } = await api.broadcastViewers.get({
        pathParams: `${modelName?.toLowerCase()}/${channelName?.toLowerCase()}/${modelProfileId}`,
      });
      this.log("getViewerCredentials success", data);
      return data;
    } catch (error) {
      this.log("getViewerCredentials failed", error);
    }
  };

  getEdgeLBPath = async () => {
    try {
      this.log("getEdgeLBPath started");
      const { data: edgeLBPath } = await axios.get(config.edgeLBPath);
      return edgeLBPath;
    } catch (error) {
      this.log("getEdgeLBPath failed", error);
    } finally {
      this.log("getEdgeLBPath finished");
    }
  };

  initializeNanoAndPlayStream = async (
    hostId: string,
    settings: NanoPlayerSettings
  ): Promise<CamsNanoPlayer> => {
    return new Promise((resolve, reject) => {
      this.log("initializeNanoAndPlayStream started");
      try {
        const player = new CamsNanoPlayer({
          hostId,
        });
        player.initAndPlay(settings);
        resolve(player);
      } catch (err) {
        this.log("initializeNanoAndPlayStream failed", err);
        reject();
      }
    });
  };

  stopNanoPlayStream = async (player: CamsNanoPlayer | null) => {
    this.log("stopNanoPlayStream started");
    if (player) {
      player.stop();
    }
    this.log("stopNanoPlayStream finished");
  };

  getPublishToken = async (
    channelName: string,
    modelProfileId: string,
    hideError: boolean = false,
    retries: number = 0
  ): Promise<string | null> => {
    try {
      this.log("getPublishToken started");
      const {
        data: { token },
      } = await api.broadcastPublishers.get({
        pathParams: `${channelName}/${channelName}/${modelProfileId}`,
      });
      if (!token) {
        if (retries < 3) {
          //if we dont get a token, wait a second and try again
          return new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
            return this.getPublishToken(
              channelName,
              modelProfileId,
              hideError,
              ++retries
            );
          });
        } else {
          throw new Error("Could not get publish token!");
        }
      } else {
        this.log("getPublishToken success");
        return token;
      }
    } catch (err) {
      this.log("getPublishToken failed, retrying", err);
      if (retries < 3) {
        return new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
          return this.getPublishToken(
            channelName,
            modelProfileId,
            hideError,
            ++retries
          );
        });
      } else {
        if (!hideError) {
          broadcastStreamStore.setBroadcastError({
            id: "broadcast-error.general-error",
            defaultMessage:
              "There seems to be something wrong. Please try again",
          });
        }

        return null;
      }
    }
  };
}

export const broadcastStreamService = new BroadcastStreamService();
