export default {
  apiUrl:
    process.env.REACT_APP_FEATURE_API_HOST ||
    process.env.API_HOST ||
    process.env.REACT_APP_API_HOST,
  affPassionApiUrl:
    process.env.REACT_APP_API_AFF_PASSION_HOST ||
    "https://feature-dating-api.logicbeta.com",
  enableLogger:
    process.env.REACT_APP_FEATURE_ENABLE_LOGGER ||
    process.env.ENABLE_LOGGER ||
    process.env.REACT_APP_ENABLE_LOGGER,
  googleCaptchaSiteKey:
    process.env.GOOGLE_CAPTCHA_SITE_KEY ||
    process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY,
  googleCaptchaBP: process.env.REACT_APP_GOOGLE_CAPTCHA_BYPASS,
  chatServer:
    process.env.REACT_APP_FEATURE_API_CHAT ||
    process.env.API_CHAT ||
    process.env.REACT_APP_API_CHAT,
  chatPath: process.env.API_CHAT_PATH || process.env.REACT_APP_API_CHAT_PATH,
  walletApiUrl:
    process.env.REACT_APP_FEATURE_API_PAYMENT ||
    process.env.API_PAYMENT ||
    process.env.REACT_APP_API_PAYMENT,
  broadcastApiUrl: process.env.REACT_APP_API_BROADCAST_PATH,
  broadcastApiSocket: process.env.REACT_APP_API_BROADCAST_SOCKET,
  broadcastEdge: process.env.REACT_APP_API_BROADCAST_EDGE || "edge",
  broadcastApp: process.env.REACT_APP_API_BROADCAST_APP || "origin",
  broadcastRtmp:
    process.env.REACT_APP_API_BROADCAST_RTMP ||
    "rtmp://wowza-new.logicbeta.com:1935",
  broadcastNanoWebsocket:
    process.env.REACT_APP_BROADCAST_NANO_WEBSOCKET ||
    "wss://nanoserver-new.logicbeta.com/ws",
  webrtcApp: process.env.REACT_APP_API_WEBRTC_APP || "webrtc",
  edgeLBPath:
    process.env.REACT_APP_EDGE_LB_PATH ||
    "https://wowza-new-edge-lb.logicbeta.com/redirect?type=client",
  rtmpPort: process.env.REACT_APP_RTMP_PORT || "1935",
  mockApi: "https://mocks-api.logicbeta.com",
  broadcastWsUrl: "ws://api.bomstudios.com:8000",
  modelWsUrl: "http://api.bomstudios.com:9000",
  localhostUrl: "http://localhost",
  membersUrl: process.env.REACT_APP_MEMBERS_URL || "https://cams.com",
  pineappleSupportUrl: "https://pineapplesupport.org/about/",
  userRole: process.env.REACT_APP_USER_ROLE || null,
  mocksEnabled: true,
  showAjaxErrors: false,
  useImageMocks: false,
  env: process.env.NODE_ENV,
  maximumImageSize: {
    size: 99999999, // 99.99 MB
    width: 3840 * 3,
    height: 3840 * 3,
  },
  nanoPool:
    process.env.REACT_APP_NANO_POOL ||
    '["stream10.cams.com","stream11.cams.com","stream12.cams.com","stream13.cams.com"]',
  graylogEnabled: JSON.parse(process.env.REACT_APP_GRAYLOG_ENABLED || "true"),
  graylogEndpoint:
    process.env.REACT_APP_GRAYLOG_ENDPOINT ||
    "https://graylog.prod.cams.run/gelf/fe/prod",
};
