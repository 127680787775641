import React, { useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import ChatStore from "../_stores/chat/ChatStore";
import { inject, observer } from "mobx-react";
import TailwindTabs from "library/components/_tailwind/tabs";
import { Tab } from "library/components/_tailwind/tabs/types";
import { BroadcastStreamState, IShowType } from "../_stores/broadcast/enums";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import BroadcastChatTab from "./chat-tab";
import { ChatModeEnum, IChatTab } from "../_stores/chat/enums";
import BroadcastChatTabLabel from "common/broadcast/broadcast-chat/chat-tab-label";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindBox from "library/components/_tailwind/box";
import TailwindIcon from "library/components/_tailwind/icon";
import ReactTooltip from "react-tooltip";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";
import PricingStore from "../_stores/pricing/PricingStore";
import TailwindPill from "library/components/_tailwind/pill";

function BodyPortal({ children }) {
  return ReactDOM.createPortal(children, document.body);
}

type Props = {
  chatStore?: ChatStore;
  nodeChatStore?: NodeChatStore;
  broadcastStore?: BroadcastStore;
  pricingStore?: PricingStore;
};

const BroadcastChatTabs: React.ComponentType<Props> = ({
  chatStore,
  nodeChatStore,
  broadcastStore,
  pricingStore,
}) => {
  const tabsRef = useRef<HTMLDivElement>(null);
  const {
    privateShowRequesterName,
    // supportChatMessagesAndNotifications,
    // supportChatMessages,
    // supportChatMessagesUnread,
  } = chatStore!;
  const { modelProducts } = pricingStore!;

  const {
    publicChatMessagesAndNotifications,
    privateChatMessagesAndNotifications,
    whisperConversations,
    whisperMembers,
    whisperUnread,
    whisperHash,
    setActiveChatTab,
    activeChatTab,
    isPrivateChatReady,
    removeWhisper,
    numberOfOnlineGuests,
    unreadWhisperCount,
    unreadMainChatMessages,
    notifications
  } = nodeChatStore!;

  const { currentShowType, streamState, isShowStarted, isInPaidShow } =
    broadcastStore!;

  const numberOfNewMessages = useMemo(() => {
    return isShowStarted ? unreadMainChatMessages : 0;
  }, [unreadMainChatMessages, isShowStarted]);

  const privateRoomLabel = useMemo(() => {
    if (currentShowType === IShowType.GROUP) {
      return (
        <BroadcastChatTabLabel
          descriptor={{
            id: "broadcast.partyTab",
            defaultMessage: "Party",
          }}
          pillContent={
            streamState === BroadcastStreamState.started &&
            !!numberOfNewMessages ? (
              <React.Fragment>{numberOfNewMessages}</React.Fragment>
            ) : undefined
          }
          isActive={activeChatTab === IChatTab.PUBLIC_OR_PRIVATE}
        />
      );
    } else if (currentShowType === IShowType.NUDE) {
      return (
        <BroadcastChatTabLabel
          descriptor={{
            id: "broadcast.nudeTab",
            defaultMessage: "Nude",
          }}
          isActive={activeChatTab === IChatTab.PUBLIC_OR_PRIVATE}
          pillContent={
            streamState === BroadcastStreamState.started &&
            !!numberOfNewMessages ? (
              <React.Fragment>{numberOfNewMessages}</React.Fragment>
            ) : undefined
          }
        />
      );
    } else if (currentShowType === IShowType.CURTAIN_DROPPED) {
      return (
        <BroadcastChatTabLabel
          descriptor={{
            id: "broadcast.goalHiddenTab",
            defaultMessage: "Goal (Hidden)",
          }}
          isActive={activeChatTab === IChatTab.ADMIN}
          pillContent={
            streamState === BroadcastStreamState.started &&
            !!numberOfNewMessages ? (
              <React.Fragment>{numberOfNewMessages}</React.Fragment>
            ) : undefined
          }
        />
      );
    } else if (currentShowType === IShowType.ADMIN) {
      return (
        <BroadcastChatTabLabel
          descriptor={{
            id: "broadcast.adminTab",
            defaultMessage: "ADMIN",
          }}
          isActive={activeChatTab === IChatTab.PUBLIC_OR_PRIVATE}
          pillContent={
            streamState === BroadcastStreamState.started &&
            !!numberOfNewMessages ? (
              <React.Fragment>{numberOfNewMessages}</React.Fragment>
            ) : undefined
          }
        />
      );
    } else {
      return (
        <BroadcastChatTabLabel
          descriptor={{
            id: "broadcast.privateTab",
            defaultMessage: "Private",
          }}
          isActive={activeChatTab === IChatTab.PUBLIC_OR_PRIVATE}
        />
      );
    }
  }, [
    currentShowType,
    numberOfNewMessages,
    isPrivateChatReady,
    activeChatTab,
    streamState,
  ]);

  const numberOfGuests = useMemo(() => {
    return isShowStarted ? numberOfOnlineGuests : 0;
  }, [isShowStarted, numberOfOnlineGuests]);

  const publicRoomLabel = useMemo(() => {
    if (currentShowType === IShowType.TIPPING) {
      return (
        <BroadcastChatTabLabel
          descriptor={{
            id: "broadcast.goalVisibleTab",
            defaultMessage: "Goal (Visible)",
          }}
          isActive={activeChatTab === IChatTab.PUBLIC_OR_PRIVATE}
          pillContent={
            streamState === BroadcastStreamState.started &&
            !!numberOfNewMessages ? (
              <React.Fragment>{numberOfNewMessages}</React.Fragment>
            ) : undefined
          }
        />
      );
    } else {
      return (
        <BroadcastChatTabLabel
          descriptor={{
            id: "broadcast.chatTab",
            defaultMessage: "Chat",
          }}
          isActive={activeChatTab === IChatTab.PUBLIC_OR_PRIVATE}
          pillContent={
            !!numberOfNewMessages ? (
              <React.Fragment>{numberOfNewMessages}</React.Fragment>
            ) : undefined
          }
        />
      );
    }
  }, [
    streamState,
    numberOfNewMessages,
    numberOfGuests,
    activeChatTab,
    currentShowType,
  ]);

  const canWhisperInTabs = useMemo(
    () =>
      modelProducts?.chat_settings?.system_settings.whispers_in_separate_tabs,
    [modelProducts]
  );

  const whisperTabs = useMemo(() => {
    const _tabs: Tab[] = [];

    if (!canWhisperInTabs) {
      return [];
    }

    const filteredNotifications = notifications.filter(n => {
      return (
        n.broadcastType === 0 ||
        n.broadcastType === 1 ||
        n.broadcastType === 2 ||
        n.broadcastType === 3 ||
        n.broadcastType === 5 ||
        n.broadcastType === 6 ||
        n.broadcastType === 12 ||
        n.broadcastType === 13
      );
    });
    
    whisperConversations.forEach(conversation => {
      const whisperMessagesAndNotifications = [...conversation?.messages, ...filteredNotifications].sort((a, b) => {
        return a.created.getTime() - b.created.getTime();
      });
      const totalWhisperCount = whisperConversations.length;
      const shouldShowDelete = totalWhisperCount <= 5 || totalWhisperCount > 5;
      _tabs.push({
        id: `${conversation?.member?.id}`,
        label: (
          <TailwindFlex width={"w-auto"} alignItems={"items-center"}>
            <TailwindIcon
              margin={["mr-1"]}
              name={"private-message"}
              textColor={"text-main-color"}
              className={
                conversation.unread > 0 &&
                conversation.member.id !== activeChatTab &&
                conversation.messages.length === 1 &&
                conversation.messages[0].isModel === false
                  ? "newtabanim"
                  : ""
              }
            />
            <TailwindBox
              data-tip
              data-for={conversation?.member?.id}
              style={{
                minWidth: 10,
              }}
              flex={"flex-1"}>
              <TailwindText
                display='block'
                width='w-full'
                overflow='overflow-hidden'
                textOverflow='overflow-ellipsis'
                className={
                  conversation.unread > 0 &&
                  conversation.member.id !== activeChatTab &&
                  conversation.messages.length === 1 &&
                  conversation.messages[0].isModel === false
                    ? "newtabanim"
                    : ""
                }
                fontWeight={"font-bold"}>
                {conversation?.member?.username}
              </TailwindText>
            </TailwindBox>
            <BodyPortal>
              <ReactTooltip
                effect='solid'
                id={conversation?.member?.id}
                className='TailwindTooltip'
                place='bottom'>
                {conversation?.member?.username}
              </ReactTooltip>
            </BodyPortal>
            {conversation?.online && (
              <TailwindBox
                margin={["ml-2"]}
                backgroundColor={"bg-green-500"}
                borderRadius={"rounded-full"}
                height={"h-2"}
                width={"w-2"}
              />
            )}
            {!!conversation?.unread &&
              activeChatTab !== conversation.member.id && (
                <TailwindPill
                  margin={["ml-1"]}
                  padding={["px-3", "py-1"]}
                  backgroundColor={"bg-pink-700"}
                  textColor={"text-white"}
                  textProps={{
                    fontWeight: "font-bold",
                  }}>
                  {conversation?.unread}
                </TailwindPill>
              )}
            {shouldShowDelete && (
              <TailwindIcon
                onClick={event => {
                  event.stopPropagation();
                  removeWhisper(conversation.member.id);
                }}
                name={"close"}
              />
            )}
          </TailwindFlex>
        ),
        component: (
          <BroadcastChatTab
            chatMode={ChatModeEnum.WHISPER}
            whisperMemberUsername={conversation?.member?.username as string}
            whisperMemberId={conversation?.member?.id as string}
            messages={whisperMessagesAndNotifications}
          />
        ),
      });
    });
    return _tabs;
  }, [
    canWhisperInTabs,
    numberOfNewMessages,
    whisperConversations,
    removeWhisper,
    activeChatTab,
    whisperHash,
    unreadWhisperCount,
    privateChatMessagesAndNotifications,
    publicChatMessagesAndNotifications,
    notifications
  ]);

  const tabs = useMemo(() => {
    const _tabs: Tab[] = [];

    if (!isInPaidShow) {
      _tabs.push({
        id: IChatTab.PUBLIC_OR_PRIVATE,
        label: publicRoomLabel,
        component: (
          <BroadcastChatTab
            chatMode={ChatModeEnum.PUBLIC}
            messages={publicChatMessagesAndNotifications}
          />
        ),
      });
      whisperTabs.forEach(tab => _tabs.push(tab));
    } else {
      const privateTab = {
        id: IChatTab.PUBLIC_OR_PRIVATE,
        label: privateRoomLabel,
        component: (
          <BroadcastChatTab
            chatMode={ChatModeEnum.PRIVATE}
            messages={privateChatMessagesAndNotifications}
          />
        ),
      };
      _tabs.push(privateTab);

      if (isInPaidShow) {
        whisperTabs.forEach(tab => _tabs.push(tab));
      }
    }
    return _tabs;
  }, [
    isInPaidShow,
    whisperMembers,
    isPrivateChatReady,
    whisperUnread,
    privateRoomLabel,
    publicRoomLabel,
    privateChatMessagesAndNotifications,
    publicChatMessagesAndNotifications,
    privateShowRequesterName,
    currentShowType,
    whisperTabs,
  ]);

  const activeTabIndex = useMemo(() => {
    return tabs.findIndex(activeTab => activeTab.id === activeChatTab);
  }, [activeChatTab, tabs]);

  useEffect(() => {
    if (!canWhisperInTabs) {
      // go back to default tab if whisper tabs are turned off
      setActiveChatTab(tabs?.[0]?.id as string);
    }
  }, [canWhisperInTabs]);

  useEffect(() => {
    const startIndex = activeTabIndex || 0;
    if (tabsRef?.current) {
      // add whisper tabs styling
      const tabs = tabsRef.current.querySelectorAll(".tab-container");
      // const tabs_container = tabsRef.current.parentElement;
      const min_tab_width = tabs.length
        ? tabsRef.current.scrollWidth / tabs.length
        : 0;
      const translate_distance = 20;

      if (tabs?.length > 4) {
        tabs?.forEach((tab, index) => {
          if (index === startIndex) {
            (tab as HTMLElement).style["transform"] = `translateX(-${
              index ? translate_distance * (index - 1) : 0
            }px`;
          } else if (index < startIndex) {
            (tab as HTMLElement).style["transform"] = `translateX(-${
              index ? translate_distance * index : 0
            }px`;
          } else if (index > startIndex) {
            (tab as HTMLElement).style["transform"] = `translateX(-${
              index === startIndex + 1
                ? Math.max(0, translate_distance * (index - 2))
                : Math.max(20, translate_distance * (index - 1))
            }px`;
          }
          (tab as HTMLElement).style["z-index"] = `${tabs.length - index}`;
          (tab as HTMLElement).style["min-width"] = `${min_tab_width}px`;
        });

        if (tabs[startIndex]) {
          const last_tab = tabs[tabs.length - 1] as HTMLElement;
          const last_tab_offset = last_tab?.getBoundingClientRect()?.right || 0;
          const container_offset =
            tabsRef.current.getBoundingClientRect().right || 0;
          const empty_space = container_offset - last_tab_offset;

          (tabs[startIndex] as HTMLElement).style["margin"] =
            startIndex === 0
              ? "0 2px 0 0"
              : startIndex === tabs.length
              ? "0 0 0 2px"
              : "0 2px";
          (tabs[startIndex] as HTMLElement).style["min-width"] = `${
            min_tab_width + empty_space
          }px`;
        }
      } else {
        tabs?.forEach(tab => {
          (tab as HTMLElement).style["transform"] = "unset";
        });
      }
    }
  }, [activeTabIndex, tabs, whisperHash]);

  return (
    <TailwindFlex ref={tabsRef} width={"w-full"} height={"h-full"}>
      <TailwindTabs
        className={["BroadcastChatTabs"]}
        flex={"flex-1"}
        overflow={"overflow-hidden"}
        key={activeChatTab}
        onChange={(_activeTabIndex, activeTabId) => {
          setActiveChatTab(activeTabId);
        }}
        activeTabIndex={activeTabIndex}
        tabs={tabs}
        height={"h-full"}
        bodyProps={{
          flexGrow: "flex-grow",
          flexDirection: "flex-col",
          height: "h-full",
          className: ["ChatTab__container"],
          textColor: "text-main-color",
          backgroundColor: "bg-tertiary-bg-color",
        }}
        headerProps={{
          margin: ["mb-2"],
          width: "w-full",
          borderColor: "border-transparent",
          backgroundColor: "bg-body-bg-color",
          buttonProps: {
            fullWidth: true,
            textColor: "text-gray-500",
            backgroundColor: "bg-white",
            className: ["hover:bg-gray-200"],
            justifyContent: "justify-center",
            height: "h-12",
            textProps: {
              width: "w-full",
            },
            borderRadius: ["rounded-t-xl"],
            style: {
              boxShadow: "2px 0px 2px rgba(0,0,0,0.2)",
              marginRight: "2px",
            },
          },
          buttonContainerProps: {
            className: ["tab-container"],
            flex: "flex-1",
            minWidth: "min-w-0",
            maxWidth: whisperTabs.length > 0 ? "max-w-30" : "max-w-none",
            backgroundColor: "bg-transparent",
            margin: tabs.length < 5 ? ["mr-1"] : [],
          },
          activeButtonProps: {
            textColor: "text-main-color",
            backgroundColor: "bg-tertiary-bg-color",
            className: ["hover:bg-tertiary-bg-color"],
            margin: ["m-1"],
            borderWidth: ["border-b-0"],
          },
        }}
      />
    </TailwindFlex>
  );
};

export default inject(
  "chatStore",
  "nodeChatStore",
  "broadcastStore",
  "pricingStore"
)(observer(BroadcastChatTabs));
