import React, { useEffect } from "react";
import "./styles.scss";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import BroadcastStartInstruction from "./broadcast-start-instruction";
import BroadcastWebrtcPreview from "./broadcast-webrtc-preview";
import BroadcastObsTimer from "./broadcast-obs-timer";
import BroadcastPausedCounter from "./broadcast-paused-counter";
import BroadcastErrorModal from "../broadcast-error-modal";
import PricingStore from "../_stores/pricing/PricingStore";
import BroadcastC2CList from "./broadcast-c2c-list";
import BroadcastShowTypeIndicator from "./broadcast-show-type-indicator";
import { BroadcastStreamState, IShowType } from "../_stores/broadcast/enums";
import TailwindCard from "library/components/_tailwind/card";
import BroadcastRecordCountdown from "./broadcast-record-countdown";
import BroadcastStreamStore from "common/broadcast/_stores/broadcast-stream/BroadcastStreamStore";
import { BroadcastStreamType } from "common/broadcast/_stores/broadcast-stream/enums";
import BroadcastSettingsOverlay from "../broadcast-settings-overlay";
import BroadcastRecordingIndicator from "common/broadcast/broadcast-preview/broadcast-recording-indicator";
import BroadcastTippingGoal from "common/broadcast/broadcast-preview/broadcast-tipping-goal";
import BroadcastObsPreview from "./broadcast-obs-preview";
import { BroadcastSettingButtons } from "./broadcast-setting-buttons";
import BroadcastPrivateMemberLeftConfirmationModal from "../broadcast-private-member-left-confirmation-modal";

type IBroadcastPreviewProps = {
  broadcastStore?: BroadcastStore;
  broadcastStreamStore?: BroadcastStreamStore;
  pricingStore?: PricingStore;
};

//const LOW_QUALITY_VIDEO = 2;

const BroadcastPreview = ({
  broadcastStore,
  broadcastStreamStore,
  pricingStore,
}: IBroadcastPreviewProps & WrappedComponentProps) => {
  const { getPriceData } = pricingStore!;
  const { mediaStream, obsStreamConnected, streamType } = broadcastStreamStore!;
  const {
    //videoQualityMarker,
    //handleVideoQualityMarker,
    currentShowType,
    shouldShowStreamingButton,
    countdownStarted,
    isRecording,
    isVideoSettingsOverlayShown,
    streamState,
  } = broadcastStore!;

  useEffect(() => {
    getPriceData();
  }, []);

  /*const handleVideoQualityMarker = useMemo(() => {
    const isBad = videoQualityMarker < LOW_QUALITY_VIDEO;
    const qualityType = isBad ? "Bad" : "Good";
    const scssType =
      videoQualityMarker == 0
        ? "normal"
        : qualityType.toLowerCase();
    const iconType = `sentiment_very_${isBad ? "dis" : ""}satisfied`;
    return (
      <>
        <span className={`BroadcastPreview__quality-text-${scssType}`}>
          {intl.formatMessage({
            id: "broadcast.broadcastPreview.videoQuality",
            defaultMessage: "Video Quality",
          })}
          : {qualityType}
        </span>

        <Icon
          className={`BroadcastPreview__quality-icon-${scssType} mr-3`}
          fontSize={"small"}
        >
          {iconType}
        </Icon>
      </>
    );
  }, [videoQualityMarker]);*/

  return (
    <TailwindCard className={["BroadcastPreview"]}>
      {isVideoSettingsOverlayShown && <BroadcastSettingsOverlay />}

      <BroadcastSettingButtons />
      <TailwindCard
        className={["BroadcastPreview__preview-box"]}
        borderRadius={"rounded-t-xl"}
        minHeight={"min-h-64"}
        position={"relative"}
        backgroundColor={"bg-primary-bg-color"}>
        <BroadcastStartInstruction />

        <BroadcastShowTypeIndicator />
        <BroadcastRecordingIndicator />

        {mediaStream && streamType === BroadcastStreamType.WEBRTC && (
          <BroadcastWebrtcPreview />
        )}

        <BroadcastTippingGoal />

        {streamState === BroadcastStreamState.stopped &&
          obsStreamConnected &&
          streamType === BroadcastStreamType.OBS && <BroadcastObsPreview />}

        {streamState === BroadcastStreamState.started &&
          currentShowType === IShowType.PAUSED && <BroadcastPausedCounter />}

        {streamState === BroadcastStreamState.started &&
          streamType === BroadcastStreamType.OBS &&
          currentShowType !== IShowType.PAUSED && <BroadcastObsTimer />}

        {shouldShowStreamingButton && countdownStarted && !isRecording && (
          <BroadcastRecordCountdown />
        )}
      </TailwindCard>

      <BroadcastC2CList />

      {/*<TailwindFlex
        width={'w-auto'}
        justifyContent={"justify-center"}
        alignItems={"items-center"}
      >
        {handleVideoQualityMarker}

        <span className={"BroadcastPreview__quality-text-normal"}>
          {intl.formatMessage({
            id: "broadcast.broadcastPreview.audioQuality",
            defaultMessage: "Audio Quality",
          })}
          : Good
        </span>
        <Icon
          className={"BroadcastPreview__quality-icon-normal"}
          fontSize={"small"}
        >
          sentiment_very_dissatisfied
        </Icon>
      </TailwindFlex>*/}

      <BroadcastPrivateMemberLeftConfirmationModal />
      <BroadcastErrorModal />
    </TailwindCard>
  );
};

export default injectIntl(
  inject(
    "broadcastStore",
    "broadcastStreamStore",
    "pricingStore",
    "showcaseStore"
  )(observer(BroadcastPreview))
);
