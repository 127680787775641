import React, { useEffect, useState, useMemo } from "react";
import { TFontSize } from "../../styles/tailwind-classnames";
import { inject, observer } from "mobx-react";
import TailwindTranslatedText from "../_tailwind/translated-text";
import TailwindText from "../_tailwind/text";
import { IShowType } from "../../../../common/broadcast/_stores/broadcast/enums";

export type TimerProps = {
  className?: string;
  fontSize?: TFontSize;
  isCountUpTimerOn?: boolean;
  currentShowType?: string;
};

const CountUpTimer: React.FunctionComponent<TimerProps> = ({
  className,
  isCountUpTimerOn,
  currentShowType,
  fontSize,
}) => {
  
  const [count, setCount] = useState(0);
  const [time, setTime] = useState("00:00:00");

  const initTime = new Date();

  const showTimer = (ms) => {
    const second = Math.floor((ms / 1000) % 60)
      .toString()
      .padStart(2, "0");
    const minute = Math.floor((ms / 1000 / 60) % 60)
      .toString()
      .padStart(2, "0");
    const hour = Math.floor(ms / 1000 / 60 / 60).toString()
      .padStart(2, "0");
    setTime(
      hour + ":" + minute + ":" + second
    );
  };

  const currentShowTranslated = useMemo(() => {
    if(currentShowType === IShowType.FREE){
      return {id:"showTypes.free", defaultMessage: "Free Chat -"};
    } else if (currentShowType === IShowType.NUDE) {
      return {id:"showTypes.nude", defaultMessage: "Nude Chat -"};
    } else if (currentShowType === IShowType.TIPPING){
      return {id:"showTypes.tipping", defaultMessage: "Goal Chat - Visible -"};
    } else if (currentShowType === IShowType.CURTAIN_DROPPED){
      return {id:"showTypes.curtain_dropped", defaultMessage: "Goal Chat - Hidden -"};
    } else if (currentShowType === IShowType.PRIVATE){
      return {id:"showTypes.private", defaultMessage: "Private Chat -"};
    } else if (currentShowType === IShowType.C2C){
      return {id:"showTypes.c2c", defaultMessage: "C2C Chat -"};
    } else if (currentShowType === IShowType.PAUSED){
      return {id:"showTypes.paused", defaultMessage: "Paused -"};
    } else if (currentShowType === IShowType.GROUP){
      return {id:"showTypes.group", defaultMessage: "Group Chat -"};
    } else if (currentShowType === IShowType.ADMIN){
      return {id:"showTypes.admin", defaultMessage: "Admin Chat -"};
    } else {
      return {id:"", defaultMessage: ""};
    }
  }, [currentShowType]);

  useEffect(() => {
    if(isCountUpTimerOn){
      const id = setInterval(() => {
        const timeLeft = count + (new Date().valueOf() - initTime.valueOf());
        setCount(timeLeft);
        showTimer(timeLeft);
      }, 1000);
      return () => clearInterval(id);
    }
  }, [isCountUpTimerOn]);

  return (
    <div className={className || "CountUpTimer"}>
      <TailwindTranslatedText
          fontSize={fontSize || "text-base"}
          descriptor={currentShowTranslated}
        /> <TailwindText fontSize={fontSize || "text-base"}>{time}</TailwindText>
    </div>
  );
};

  export default inject(

  )(observer(CountUpTimer));